import React from "react";
import { List, ListItem, ListItemText, IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { toast } from "react-toastify";
import { getApiData } from "../../../../../utils/APIHelper";
import { Setting } from "../../../../../utils/Setting";
import DescriptionIcon from "@mui/icons-material/Description";

const MilestoneList = ({ arr, setDeleteFile, setLoader, setShowDetails }) => {
  async function handleDelete(id, milestoneId) {
    setLoader(true);
    try {
      const response = await getApiData(
        `${Setting.endpoints.deleteProjectImageById(id)}`,
        "POST"
      );
      if (response.success) {
        setShowDetails({
          status: false,
        });
        setDeleteFile({
          status: true,
          milestoneId: milestoneId,
        });
        toast.success("File deleted successfully");
        setLoader(false);
      }
    } catch (error) {
      toast.error(error);
      setLoader(false);
    }
  }

  return (
    <List
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "8px",
        maxHeight: "280px",
        overflowY: "auto",
        scrollbarWidth: "none",
        msOverflowStyle: "none",
      }}
      sx={{
        "&::-webkit-scrollbar": {
          display: "none",
        },
      }}
    >
      {arr[0]?.files?.map((file, index) => (
        <ListItem
          key={file.id}
          sx={{
            mb: 1,
            p: 1,
            border: "1px solid #ddd",
            borderRadius: 1,
            display: "flex",
            alignItems: "center",
          }}
        >
          {file?.file_extention === "application/pdf" ? (
            <DescriptionIcon
              sx={{
                marginRight: "1vw",
                padding: 1,
                width: "50px",
                height: "50px",
              }}
            />
          ) : (
            <img
              src={file?.path}
              alt={
                file?.file_extention === "application/pdf" ? "File" : "Image"
              }
              style={{
                height: "50px",
                width: "50px",
                marginRight: "1vw",
                alignItems: "center",
                display: "flex",
              }}
            />
          )}
          <ListItemText
            primary={
              <a
                href={file?.path}
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  textDecoration: "none",
                  color: "blue",
                  fontSize: "15px",
                }}
              >
                {`${
                  file?.file_extention === "application/pdf" ? "File" : "Image"
                }_${index + 1}`}
              </a>
            }
            sx={{ flexGrow: 1 }}
          />
          <IconButton
            color="inherit"
            onClick={() => handleDelete(file?.id, file?.assign_id)}
          >
            <DeleteIcon />
          </IconButton>
        </ListItem>
      ))}
    </List>
  );
};

export default MilestoneList;
