import { removeEmptyKeys } from "../../utils/CommonFunction";

import moment from "moment";

export const getCreateProposalPayload = (proposalDetails, projectFiles) => {
  let transformedData = {
    email: proposalDetails?.email,
    name: proposalDetails?.name,
    username: proposalDetails?.customer_name,
    project_type: proposalDetails?.project_type,
    exp_id: proposalDetails?.project_type?.id || 1,
    start_date: moment(proposalDetails?.start_date).format("MMMM DD, yyyy"),
    end_date: moment(proposalDetails?.end_date).format("MMMM DD, yyyy"),
    description: proposalDetails?.description,
    project_image: projectFiles,
    proposal: JSON.stringify({
      scope_of_work: proposalDetails?.scope_of_work,
      milestone_details: Object.keys(proposalDetails.milestones).map(
        (milestoneKey) => {
          const milestone = proposalDetails?.milestones[milestoneKey];
          return {
            payment_group_id: milestone?.group_id,
            id: milestone?.id,
            milestone_name: milestone?.milestone_name,
            description: milestone?.description,
            tags:milestone?.tags,
            start_date: moment(milestone?.start_date).format("MMMM DD, yyyy"),
            end_date: moment(milestone?.end_date).format("MMMM DD, yyyy"),
            budget_item: Object.keys(proposalDetails.budgets)
              .map((key, i) => {
                const budget = proposalDetails?.budgets[key];
                if (budget.milestone_id == milestoneKey) {
                  return {
                    id: budget?.id,
                    budget_id: i + 1,
                    name: budget?.name,
                    material_type: budget?.material_type,
                    material_unit: budget?.material_unit || "",
                    material_unit_price: budget?.material_unit_price || "0",
                    qty: budget?.qty || "0",
                    manpower_rate: budget?.manpower_rate || "0",
                    days: budget?.days || "0",
                    specification: budget?.specification,
                  };
                } else {
                  return null;
                }
              })
              .filter((budget) => budget !== null),
          };
        }
      ),
      payment_group_details: Object.keys(proposalDetails?.payment_group).map(
        (key) => {
          const payment_group = proposalDetails?.payment_group[key];
          return {
            group_id: key,
            group_name: payment_group?.groupName,
          };
        }
      ),
    }),
  };
  Object.values(proposalDetails.budgets).forEach((budget, index) => {
    const photoOriginFiles = convertPhotoOriginToFiles(budget);
    transformedData[`budget_image_${index + 1}`] = photoOriginFiles;
  });
  if (proposalDetails.proposal_id) {
    transformData.proposal_id = proposalDetails.proposal_id;
  }
  return transformedData;
};

export const convertBase64ToImageFile = (base64String, filename) => {
  if (typeof base64String !== 'string') {
    console.error('Invalid base64 string');
    return null; // Or handle the error appropriately
  }

  try {
    const arr = base64String.split(",");
    if (arr.length < 2) {
      throw new Error('Invalid base64 string format');
    }

    const mimeType = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const uint8Array = new Uint8Array(n);

    while (n--) {
      uint8Array[n] = bstr.charCodeAt(n);
    }

    const file = new File([uint8Array], filename, { type: mimeType });
    return file;
  } catch (error) {
    console.error('Error converting base64 to image file:', error);
    return null; // Or handle the error appropriately
  }
};

export const convertPhotoOriginToFiles = (budget) => {
  const photoOriginFiles = budget?.photo_origin?.map((base64String, index) => {
    const filename = `photo_origin_${index + 1}.jpg`;

    return convertBase64ToImageFile(base64String, filename);
  });
  return photoOriginFiles;
};

function transformData(proposalDetails, projectFiles) {
  const transformedData = removeEmptyKeys({
    customer_email: proposalDetails?.email,
    name: proposalDetails?.name,
    username: proposalDetails?.customer_name,
    project_type: proposalDetails?.project_type,
    exp_id: proposalDetails?.project_type?.id || 1,
    start_date: moment(proposalDetails?.start_date).format("MMMM DD, yyyy"),
    end_date: moment(proposalDetails?.end_date).format("MMMM DD, yyyy"),
    description: proposalDetails?.description,
    project_image: projectFiles,
    scope_of_work: proposalDetails?.scope_of_work,
    proposal: removeEmptyKeys({
      milestone_details: Object.keys(proposalDetails.milestones).map(
        (milestoneKey) => {
          const milestone = proposalDetails.milestones[milestoneKey];
          return {
            payment_group_id: milestone?.group_id,
            milestone_name: milestone?.milestone_name,
            description: milestone?.description,
            start_date: moment(milestone?.start_date).format("MMMM DD, yyyy"),
            end_date: moment(milestone?.end_date).format("MMMM DD, yyyy"),
            tags:milestone?.tags,
            budget_item: Object.keys(proposalDetails.budgets)
              .map((key, i) => {
                const budget = proposalDetails.budgets[key];
                if (budget.milestone_id == milestoneKey) {
                  return {
                    name: budget?.name,
                    budget_id: i + 1,
                    material_type: budget?.material_type,
                    material_unit: budget?.material_unit || "",
                    material_unit_price: budget?.material_unit_price || "0",
                    qty: budget?.qty || "0",
                    manpower_rate: budget?.manpower_rate || "0",
                    days: budget?.days || "0",
                    specification: budget?.specification,
                  };
                } else {
                  return null;
                }
              })
              .filter((budget) => budget !== null),
          };
        }
      ),
      payment_group_details: Object.keys(proposalDetails.payment_group).map(
        (key) => {
          const payment_group = proposalDetails.payment_group[key];
          return {
            group_id: key,
            group_name: payment_group?.groupName,
          };
        }
      ),
    }),
  });
  Object.values(proposalDetails.budgets).forEach((budget, index) => {
    const photoOriginFiles = convertPhotoOriginToFiles(budget);
    transformedData[`budget_image_${index + 1}`] = photoOriginFiles;
  });
  if (transformedData.hasOwnProperty("proposal")) {
    transformedData.proposal = JSON.stringify(transformedData.proposal);
  }
  return transformedData;

  // Remove keys with empty values
  // const filteredTransformedData = Object.keys(transformedData).reduce((acc, key) => {
  //   console.group("transformed data")
  //   console.log(transformedData);
  //   console.log(key);
  //   if (
  //     transformedData[key] &&
  //     !(
  //       Array.isArray(transformedData[key]) && transformedData[key].length === 0
  //     ) &&
  //     !(
  //       typeof transformedData[key] === "object" &&
  //       Object.keys(transformedData[key]).length === 0
  //     )
  //   ) {
  //     console.log("added key", key)
  //     acc[key] = transformedData[key];
  //   }
  //   return acc;
  // }, {});
}

// :todo add project files in here, or to be added from every step
export const getSaveDraftPayload = ({ proposalDetails, projectFiles }) => {
  console.log(JSON.stringify(proposalDetails));
  return transformData(proposalDetails, projectFiles);
};

// export function transformMilestoneData(
//   proposalDetails,
//   projectFiles,
//   milestones,
//   paymentGroup
// ) {
//   const transformedData = {
//     email: proposalDetails?.email,
//     name: proposalDetails?.name,
//     username: proposalDetails?.customer_name,
//     project_type: proposalDetails?.project_type,
//     exp_id: proposalDetails?.project_type?.id || 1,
//     start_date: proposalDetails?.start_date,
//     end_date: proposalDetails?.end_date,
//     description: proposalDetails?.description,
//     project_image: projectFiles,
//     scope_of_work: proposalDetails?.scope_of_work,
//     proposal: JSON.stringify({
//       milestone_details: Object.keys(milestones).map((milestoneKey) => {
//         const milestone = milestones[milestoneKey];
//         return {
//           payment_group_id: milestone?.group_id,
//           milestone_name: milestone?.milestone_name,
//           description: milestone?.description,
//           start_date: milestone?.start_date,
//           end_date: milestone?.end_date,
//           budget_item: Object.keys(proposalDetails.budgets)
//             .map((key, i) => {
//               const budget = proposalDetails?.budgets[key];
//               if (budget.milestone_id == milestoneKey) {
//                 return {
//                   name: budget?.name,
//                   budget_id: i + 1,
//                   material_type: budget?.material_type,
//                   material_unit: budget?.material_unit || "",
//                   material_unit_price: budget?.material_unit_price || "0",
//                   qty: budget?.qty || "0",
//                   manpower_rate: budget?.manpower_rate || "0",
//                   days: budget?.days || "0",
//                   specification: budget?.specification,
//                 };
//               } else {
//                 return null;
//               }
//             })
//             .filter((budget) => budget !== null),
//         };
//       }),
//       payment_group_details: paymentGroup ? Object.keys(paymentGroup).map((key) => {
//         const payment_group = paymentGroup[key];
//         return {
//           group_id: key,
//           group_name: payment_group?.groupName,
//         };
//       }) : {},
//     }),
//   };
//   const filteredTransformedData = Object.keys(transformedData).reduce((acc, key) => {
//     if (transformedData[key] &&
//       !(Array.isArray(transformedData[key]) && transformedData[key].length === 0) &&
//       !(typeof transformedData[key] === 'object' && Object.keys(transformedData[key]).length === 0)) {
//       acc[key] = transformedData[key];
//     }
//     return acc;
//   }, {});
//   return filteredTransformedData;
// }
//
// export function transformBudgetData(
//   proposalDetails,
//   projectFiles,
//   milestones,
//   budgets
// ) {
//   return {
//     email: proposalDetails?.email,
//     name: proposalDetails?.name,
//     username: proposalDetails?.customer_name,
//     project_type: proposalDetails?.project_type,
//     exp_id: proposalDetails?.project_type?.id || 1,
//     start_date: proposalDetails?.start_date,
//     end_date: proposalDetails?.end_date,
//     description: proposalDetails?.description,
//     project_image: projectFiles,
//     scope_of_work: proposalDetails?.scope_of_work,
//     proposal: JSON.stringify({
//       milestone_details: Object.keys(milestones).map((milestoneKey) => {
//         const milestone = milestones[milestoneKey];
//         return {
//           payment_group_id: milestone?.group_id,
//           milestone_name: milestone?.milestone_name,
//           description: milestone?.description,
//           start_date: milestone?.start_date,
//           end_date: milestone?.end_date,
//           budget_item: Object.keys(budgets)
//             .map((key, i) => {
//               const budget = budgets[key];
//               if (budget.milestone_id == milestoneKey) {
//                 return {
//                   name: budget?.name,
//                   budget_id: i + 1,
//                   material_type: budget?.material_type,
//                   material_unit: budget?.material_unit || "",
//                   material_unit_price: budget?.material_unit_price || "0",
//                   qty: budget?.qty || "0",
//                   manpower_rate: budget?.manpower_rate || "0",
//                   days: budget?.days || "0",
//                   specification: budget?.specification,
//                 };
//               } else {
//                 return null;
//               }
//             })
//             .filter((budget) => budget !== null),
//         };
//       }),
//
//       // payment_group_details: Object.keys(paymentGroup).map((key) => {
//       //   const payment_group = paymentGroup[key];
//       //   return {
//       //     group_id: key,
//       //     group_name: payment_group?.groupName,
//       //   };
//       // }),
//     }),
//   };
// }
//
// export const getSaveDraftPayload = (proposalDetails, projectFiles) => {
//   return {
//     customer_email: proposalDetails?.email,
//     name: proposalDetails?.name,
//     username: proposalDetails?.customer_name,
//     project_type: proposalDetails?.project_type,
//     exp_id: proposalDetails?.project_type?.id || 1,
//     start_date: proposalDetails?.start_date,
//     end_date: proposalDetails?.end_date,
//     description: proposalDetails?.description,
//     project_image: projectFiles,
//     proposal: JSON.stringify({
//       scope_of_work: proposalDetails?.scope_of_work,
//       milestone_details: Object.keys(proposalDetails.milestones).map(
//         (milestoneKey) => {
//           const milestone = proposalDetails?.milestones[milestoneKey];
//           return {
//             payment_group_id: milestone?.group_id,
//             milestone_name: milestone?.milestone_name,
//             description: milestone?.description,
//             start_date: milestone?.start_date,
//             end_date: milestone?.end_date,
//             budget_item: Object.keys(proposalDetails.budgets)
//               .map((key, i) => {
//                 const budget = proposalDetails?.budgets[key];
//                 if (budget.milestone_id == milestoneKey) {
//                   return {
//                     name: budget?.name,
//                     budget_id: i + 1,
//                     material_type: budget?.material_type,
//                     material_unit: budget?.material_unit || "",
//                     material_unit_price: budget?.material_unit_price || "0",
//                     qty: budget?.qty || "0",
//                     manpower_rate: budget?.manpower_rate || "0",
//                     days: budget?.days || "0",
//                     specification: budget?.specification,
//                   };
//                 } else {
//                   return null;
//                 }
//               })
//               .filter((budget) => budget !== null),
//           };
//         }
//       ),
//     }),
//   };
// };
//
