import types from "./actions";

const initialState = {
  isConfirmModalSaveDraftOpen: false,
  issCancelModalOpen: false
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types.SET_IS_CONFIRM_MODAL_SAVE_DRAFT_OPEN:
      return {
        ...state,
        isConfirmModalSaveDraftOpen: action.isConfirmModalSaveDraftOpen,
      };
    case types.SET_IS_CANCEL_MODAL_OPEN:
      return {
        ...state,
        isCancelModalOpen: action.isCancelModalOpen
      };

    default:
      return state;
  }
}
