import { Backdrop, Box, Button, Fade, FormControl, Grid, Modal, useMediaQuery } from "@mui/material";
import { Close } from "@mui/icons-material";
import Images from "../../../../config/images";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import moment from "moment/moment";
import React, { useState } from "react";
import { useTheme } from "@emotion/react";
import { getApiData } from "../../../../utils/APIHelper";
import { Setting } from "../../../../utils/Setting";
import { toast } from "react-toastify";
const modalStyle = {
  display: "flex",
  flexDirection: "column",
  gap: "16px",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "20%",
  minWidth: "300px",
  height: "250px",
  bgcolor: "background.paper",
  padding: 4,
  overflow: "hidden",
};

export default function RequestInspectionModal({isOpen, handleClose, paymentGroup}) {
  const theme = useTheme();
  const [inspectionDate, setInspectionDate] = useState(new Date());
  const [isLoading, setIsLoading] = useState(false);
  const sm = useMediaQuery(theme.breakpoints.down("sm"));
  const handleRequestInspection = async () => {
    const payload = {
      groupId: paymentGroup?.group_id,
      inspectionDate: moment(inspectionDate).toISOString(),
      requested: true
    }

    setIsLoading(true);
    try {
      const response = await getApiData(
        `${Setting.endpoints.inspections}`,
        "PUT",
        payload,
        false,
        true,
      );
      toast.success("Inspection requested successfully");
      handleClose();
      setIsLoading(false);
    } catch (error) {
      console.log("Request inspection api error", error);
      toast.error(error.toString());
      setIsLoading(false);
    }

  }
  return (
    <Modal open={isOpen} onClose={handleClose}
           slotProps={{ backdrop: Backdrop }}
    >
      <Fade in={isOpen}>
        <Box sx={modalStyle}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div className="addMilestoneHeader">Request Inspection</div>
            <Close
              style={{ cursor: "pointer" }}
              onClick={() => handleClose()}
            />
          </div>
          <Grid container>
            <Grid item xs={12} mt={2}>
              <FormControl>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  disablePast
                  value={inspectionDate}
                  onChange={(date, v) => {
                    setInspectionDate(date);
                  }}
                  components={{
                    OpenPickerIcon: () => (
                      <img
                        src={Images.calendarIcon}
                        alt="calender-icon"
                      ></img>
                    ),
                  }}
                  format="MMMM dd, yyyy"
                />
              </LocalizationProvider>
            </FormControl>

          </Grid>
          </Grid>
          <Grid
            item
            container
            justifyContent={"center"}
            gap={sm ? 1 : 2}
            wrap="nowrap"
            marginTop={"10px"}
          >
            <Grid item xs={6}>
              <div className="cancel" onClick={handleClose}>
                Cancel
              </div>
            </Grid>
            <Grid item xs={6}>
              <Button
                fullWidth
                variant="contained"
                onClick={() => handleRequestInspection(false)}
                disabled={isLoading}
              >
                Request
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Fade>
    </Modal>
  );
}
