import React from 'react';
import { List, ListItem, ListItemText, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import DescriptionIcon from '@mui/icons-material/Description'; 
import { toast } from 'react-toastify';
import { getApiData } from '../../utils/APIHelper';
import { Setting } from '../../utils/Setting';

const ProposalList = ({ arr, setIsCalled }) => {
  async function handleDelete(id) {
    try {
      const response = await getApiData(`${Setting.endpoints.deleteProjectImageById(id)}`, "POST");
      if (response.success) {
        toast.success("File deleted successfully");
        setIsCalled(true)
      }
    } catch (error) {
      toast.error(error);
    }
  }
  return (
      <List>
        {arr?.map((file) => (
          <ListItem key={file.id} sx={{ mb: 1, p: 1, border: '1px solid #ddd', borderRadius: 1, display: 'flex', alignItems: 'center' }}>
            <DescriptionIcon sx={{ mr: 2 }} /> 
            <ListItemText
              primary={
                <a href={file.image} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'inherit' }}>
                  {`File ${file?.id}`}
                </a>
              }
              sx={{ flexGrow: 1 }}
            />
            <IconButton 
              color="error" 
              onClick={() => handleDelete(file.id)}
            >
              <DeleteIcon />
            </IconButton>
          </ListItem>
        ))}
      </List>
  );
};

export default ProposalList;
