import { styled } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const Status = styled("span")(({ theme, status }) => {
  let backgroundColor;
  let color;

  switch (status) {
    case "awaiting-approval":
      backgroundColor = "#b6d2f1";
      color = "#060692";
      break;
    case "awaiting-contractor-confirmation":
      backgroundColor = "#FFF3DF";
      color = "#272709";
      break;
    case "rejected-by-contractor":
      backgroundColor = "#ecc2c1";
      color = "#500404";
      break;
    case "proposed-to-client":
      backgroundColor = "#CCEEE9";
      color = "#034d03";
      break;
    case "proposal":
      backgroundColor = "#CCEEE9";
      color = "#034d03";
      break;
    default:
      backgroundColor = "#FFF3DF";
      color = "#272709";
  }

  return {
    display: "flex",
    justifyContent: "center",
    color,
    backgroundColor,
    padding: "4px 10px",
    fontSize: "10px",
    lineHeight: "16px",
    borderRadius: 4,
    fontFamily: "Poppins-Regular",
  };
});

const useStyles = makeStyles((theme) => ({
  MainContainer: {
    width: "100%",
    backgroundColor: "#FFFFFF",
    borderRadius: 8,
    boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.04)",
    border: "1px solid #F2F4F7",
    padding: "20px 20px 20px 20px",
  },

  imageStyle: {
    height: "80px",
    width: "80px",
    borderRadius: "50%",
    objectFit: "cover",
  },

  activeContainer: {
    position: "absolute",
    bottom: 5,
    right: 5,
  },

  activeStatus: {
    width: 16,
    height: 16,
    borderRadius: 16,
    backgroundColor: "#32D583",
    border: "1.5px solid #FFFFF",
  },

  titleText: {
    fontSize: "16px !important",
    lineHeight: "25px !important",
    fontFamily: "Poppins-Regular !important",
    fontWeight: "500 !important",
  },

  requestDate: {
    fontSize: "14px !important",
    lineHeight: "20px !important",
    fontFamily: "Poppins-Regular !important",
    fontWeight: "500 !important",
    color: "#646F86",
  },

  dateStyle: {
    fontSize: "16px !important",
    lineHeight: "24px !important",
    fontFamily: "Poppins-Medium !important",
    color: "#202939",
    textAlign: "end",
    fontWeight: "bold",
  },

  acctext: {
    fontFamily: "Poppins-Regular",
    fontSize: "16px !important",
    lineHeight: "20px !important",
    fontWeight: "400 !important",
  },

  accRightText: {
    fontFamily: "Poppins-Regular !important",
    fontSize: "14px !important",
    lineHeight: "24px !important",
    fontWeight: "500 !important",
    "& img": {
      width: 140,
      height: 140,
    },
  },

  linkText: {
    fontFamily: "Poppins-Regular !important",
    fontSize: "14px !important",
    lineHeight: "24px !important",
    fontWeight: "500 !important",
  },

  titleStyle: {
    fontFamily: "Poppins-Regular !important",
    fontSize: "16px !important",
    lineHeight: "20px !important",
    fontWeight: "400 !important",
  },

  titleStyleRight: {
    fontFamily: "Poppins-Regular !important",
    fontSize: "16px !important",
    lineHeight: "24px !important",
    fontWeight: "500 !important",
  },

  MainTitle: {
    fontFamily: "Poppins-Regular !important",
    fontSize: "22px !important",
    lineHeight: "32px !important",
    fontWeight: "500 !important",
  },

  paraStyle: {
    fontFamily: "Poppins-Regular !important",
    fontSize: "14px !important",
    lineHeight: "20px !important",
    fontWeight: "400 !important",
    letterSpacing: "0.25px !important",
  },

  dataMain: {
    marginTop: 20,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: 500,
  },

  table: {
    "& td": {
      width: "30%",
      fontFamily: "Poppins-Regular !important",
    },
    "& .MuiTableCell-head": {
      fontWeight: "bold !important",
      fontFamily: "Poppins-Regular !important",
    },
  },

  projectInformation: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    padding: "16px",
    borderRadius: "10px",
    fontFamily: "Poppins-Medium !important",
  },

  informationCard: {
    fontSize: "14px !important",
    padding: "0 12px",
    color: "#646f86",
    fontFamily: "Poppins-Regular !important",
  },

  label: {
    fontSize: "16px !important",
    fontFamily: "Poppins-Regular !important",
  },

  heading: {
    fontSize: "20px !important",
    fontFamily: "Poppins-Regular !important",
  },

  value: {
    fontSize: "16px !important",
    padding: "4px 12px",
    fontFamily: "Poppins-Medium !important",
  },

  notesContainer: {
    padding: "4px 12px",
    borderRadius: "8px",
    backgroundColor: "#F3F4F9",
  },

  tableRow: {
    padding: "16px 10px !important",
    fontSize: "16px !important",
    fontFamily: "Poppins-Medium !important",
    border: "1px solid rgba(224, 224, 224, 1)",
    borderCollapse: "collapse",
    backgroundColor: "#F3F4F9",
  },

  amountRow: {
    textAlign: "end !important",
    padding: "16px 40px 16px 10px !important",
    fontSize: "16px !important",
    border: "1px solid rgba(224, 224, 224, 1)",
    borderCollapse: "collapse",
    backgroundColor: "#F3F4F9",
    fontFamily: "Poppins-Medium !important",
  },

  cell: {
    padding: "14px 10px !important",
    fontSize: "14px !important",
    border: "1px solid rgba(224, 224, 224, 1)",
    borderCollapse: "collapse",
    fontFamily: "Poppins-Regular !important",
  },

  amountCell: {
    textAlign: "end !important",
    padding: "16px 40px 16px 10px !important",
    fontSize: "14px !important",
    border: "1px solid rgba(224, 224, 224, 1)",
    borderCollapse: "collapse",
    fontFamily: "Poppins-Regular !important",
  },
}));

export default useStyles;
