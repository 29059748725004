import { useEffect, useState } from "react";
import useStyles from "../styles";
import ChangelogStatus from "./ChangelogStatus";
import { CHANGE_REQUEST_STATUS } from "../../../../config/constants";
import { toast } from "react-toastify";
import { Button, Collapse, Divider, IconButton, Stack } from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import ChangeRequestDetails from "./ChangeRequestDetails";
import { Setting } from "../../../../utils/Setting";
import { getApiData } from "../../../../utils/APIHelper";
import { formattedAmount } from "../../../../utils/CommonFunction";

const ChangeRequestItem = ({ changelog }) => {
  const [state, setState] = useState();
  const [open, setOpen] = useState();
  const [loading, setLoading] = useState();

  const classes = useStyles();

  useEffect(() => {
    if (changelog) {
      setState(changelog);
    }
  }, [changelog]);

  const handleApproveClick = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    setLoading(true);

    try {
      const data = await getApiData(
        Setting.endpoints.changeRequest.approve(
          changelog?.changeRequest?.changeRequestId
        ),
        "PUT",
        {},
        {},
        true
      );
      setState((prev) => ({
        ...prev,
        changeRequest: {
          ...prev.changeRequest,
          status: CHANGE_REQUEST_STATUS?.AWAITING_HOME_OWNER_CONFIRMATION,
        },
      }));
      toast.success("Change Request Approved");
    } catch (err) {
      toast.error(err.message || "Something went wrong!");
    } finally {
      setLoading(false);
    }
  };

  const toggleView = () => {
    setOpen((prev) => !prev);
  };

  if (!state) {
    return;
  }

  return (
    <Stack className={classes.changelogItem}>
      <Stack direction="row" className={classes.summaryContainer}>
        <div className={classes.titleContainer}>
          <div
            className={classes.titleItem}
            style={{ flexGrow: 1, textTransform: "capitalize" }}
          >
            {state?.proposalMilestone?.milestoneName}
          </div>
          <div style={{ display: "flex", gap: "10px" }}>
            <div
              className={
                state?.changeRequest.changeRequestCost
                  ? classes.valueWidth
                  : classes.noValueWidth
              }
            >
              Cost:&nbsp;
              {formattedAmount(state?.changeRequest?.changeRequestCost)}
            </div>
          </div>
        </div>
        <div className={classes.actionContainer}>
          <div className={classes.approveContainer}>
            {state?.changeRequest.status ===
              CHANGE_REQUEST_STATUS?.AWAITING_CONTRACTOR_CONFIRMATION && (
              <Button
                onClick={handleApproveClick}
                variant="contained"
                color="primary"
                disabled={loading}
              >
                Approve
              </Button>
            )}
          </div>
          <IconButton onClick={toggleView}>
            {open ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        </div>
      </Stack>
      <Collapse in={open}>
        <Stack width="100%" className={classes.contentContainer}>
          <Divider />
          <ChangeRequestDetails
            open={open}
            changeRequestId={changelog.changeRequest.changeRequestId}
          />
        </Stack>
      </Collapse>
      <Divider />
      <div className={classes.footerContainer}>
        <ChangelogStatus
          status={state.changeRequest.status}
          // approvedOn={
          //   ![
          //     CHANGE_REQUEST_STATUS.AWAITING_CONTRACTOR_CONFIRMATION,
          //     CHANGE_REQUEST_STATUS.DRAFT,
          //   ].includes(state.changeRequest.status)
          //     ? state.changeRequest.createdAt
          //     : null
          // }
          moveInDate={state.changeRequest.newMoveInDate}
        />
      </div>
    </Stack>
  );
};

export default ChangeRequestItem;
