import {
  CircularProgress,
  Grid,
  Tab,
  Tabs,
  useMediaQuery,
} from "@mui/material";
import useStyles from "./styles";
import { useEffect, useState, useCallback } from "react";
import BlueAbout from "../../components/BlueAbout";
import ProposalCard from "../../components/ProposalCard";
import Summary from "./Summary";
import Milestones from "./Milestones";
import Budgets from "./Budgets";
import PaymentGroup from "./PaymentGroup";
import Details from "./Details";
import { useLocation } from "react-router-dom";
import { isMobile, isTablet } from "react-device-detect";
import theme from "../../config/theme";
import authActions from "../../redux/reducers/auth/actions";
import { useDispatch, useSelector } from "react-redux";
import { isArray, isEmpty, isObject } from "lodash";
import TabActions from "./TabActions";
import { transformDateFormat } from "../../utils/CommonFunction";
import { toast } from "react-toastify";
import { getApiData } from "../../utils/APIHelper";
import { Setting } from "../../utils/Setting";

const CreateProposal = () => {
  const classes = useStyles();
  const location = useLocation();
  const dispatch = useDispatch();
  const { setProposalDetails, setErrors, resetProposalDetails, resetErrors } =
    authActions;
  const md = useMediaQuery(theme.breakpoints.down("md"));
  const isCreateProposal = location?.state?.isCreateProposal || false;
  const isDraft = location?.state?.isDraft || false;
  const villa = location?.state?.villa ? location?.state?.villa : {};
  const [generatedVilla, setGeneratedVilla] = useState({});
  const { proposalDetails, errors } = useSelector((state) => state.auth);
  const isUpdateDraftProposal = Boolean(location?.state?.isUpdateDraftProposal);
  const [tabValue, setTabValue] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    // Cleanup function to reset state when the component unmounts
    return () => {
      dispatch(resetProposalDetails());
      dispatch(resetErrors());
    };
  }, [dispatch]);
  // const fetchImages = async () => {
  //   const projectImageArr = [];
  //   const images = await Promise.all(
  //     villa?.project?.project_image.map(async (item) => {
  //       const response = await imageUrlToBase64(item.image);
  //       return response;
  //     }) || []
  //   );
  //   projectImageArr.push(...images);
  //   return projectImageArr;
  // };

  const transformToVilla = (proposal_id, data) => {
    const {
      project_type,
      name,
      description,
      username,
      customer_email,
      scope_of_work,
      project_image,
      milestones,
      budgets,
      payment_groups,
    } = data;
    return {
      proposal_id,
      project: {
        project_type,
        name,
        description,
        username,
        customer_email,
        scope_of_work,
        project_image,
      },
      milestones,
      budgets,
      payment_groups,
    };
  };

  const transformImages = useCallback(async (data) => {
    const budgetImagesArr = [];
    const images = await Promise.all(
      data?.map(async (item) => {
        const response = await imageUrlToBase64(item.image);
        return response;
      }) || []
    );
    budgetImagesArr.push(...images);
    return budgetImagesArr;
  }, []);

  const transformSummaryData = useCallback((summary) => {
    const {
      project_type,
      name,
      description,
      username,
      customer_email,
      scope_of_work,
      project_image,
      exp_id,
      start_date,
      end_date,
      form_json,
      dimensions
    } = summary;

    return {
      project_type: project_type ?? "",
      name: name ?? "",
      description: description ?? "",
      customer_name: username ?? "",
      email: customer_email ?? "",
      scope_of_work: scope_of_work ?? "",
      uploadedImages: project_image,
      exp_id: exp_id ?? "",
      start_date: start_date ?? "",
      end_date: end_date ?? "",
      dimensions: JSON?.parse(dimensions) ?? "",
      form_json: JSON?.parse(form_json) ?? {},
    };
  }, []);

  const transformMilestonesData = useCallback((milestones, paymentGroups) => {
    const transformedMilestones = {};

    milestones.forEach((milestone) => {
      const {
        id,
        milestone_name,
        description,
        start_date,
        end_date,
        amount,
        tags,
      } = milestone;
      transformedMilestones[id] = {
        id,
        milestone_name: milestone_name ?? "",
        description: description ?? "",
        start_date: start_date ?? "",
        end_date: end_date ?? "",
        tags: tags ?? "",
        amount,
        group_id: "",
        in_payment_group: false,
      };
    });

    paymentGroups.forEach((paymentGroup) => {
      const { group_id, milestones: paymentGroupMilestones } = paymentGroup;
      paymentGroupMilestones.forEach((milestone) => {
        transformedMilestones[milestone.id].group_id = group_id;
        transformedMilestones[milestone.id].in_payment_group = true;
      });
    });

    return transformedMilestones;
  }, []);

  const transformBudgetsData = useCallback(
    (budgets) => {
      const transformedBudgets = {};

      budgets.forEach(async (budget) => {
        const {
          id,
          name,
          material_type,
          material_unit,
          material_unit_price,
          milestone_id,
          qty,
          manpower_rate,
          days,
          specification,
          updatedAt,
          total_item,
          budget_image,
          buget_image,
        } = budget;

        const budgetImage = await transformImages(
          budget_image ?? buget_image ?? []
        );

        transformedBudgets[id] = {
          id,
          name: name ?? "",
          material_type: material_type ?? "",
          material_unit: material_unit ?? "",
          material_unit_price:
            material_unit_price > 0 ? material_unit_price : "",
          milestone_id: milestone_id ?? "",
          qty: qty > 0 ? qty : "",
          manpower_rate: manpower_rate > 0 ? manpower_rate : "",
          days: days > 0 ? days : "",
          specification: specification ?? "",
          updatedAt: transformDateFormat(updatedAt),
          amount: total_item ?? 0,
          photo_origin: budgetImage ?? [],
          budget_image_id: buget_image?.[0]?.id ?? "",
          buget_image: budget_image ?? [],
        };
      });

      return transformedBudgets;
    },
    [transformImages]
  );

  const transformPaymentGroupsData = useCallback((paymentGroups) => {
    const transformedPaymentGroups = {};

    paymentGroups.forEach((paymentGroup) => {
      const { group_id, group_name, due_date, milestones } = paymentGroup;

      const transformedMilestones = {};

      milestones.forEach((milestone) => {
        const {
          id,
          milestone_name,
          description,
          start_date,
          end_date,
          amount,
          tags,
        } = milestone;

        transformedMilestones[id] = {
          milestone_name: milestone_name ?? "",
          description: description ?? "",
          start_date: start_date ?? "",
          end_date: end_date ?? "",
          tags: tags ?? "",
          amount: amount ?? 0,
          group_id: group_id ?? "",
          in_payment_group: true,
        };
      });

      transformedPaymentGroups[group_id] = {
        groupName: group_name,
        dueDate: due_date,
        group: transformedMilestones,
      };
    });

    return transformedPaymentGroups;
  }, []);

  const getProjectDetails = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await getApiData(
        `${Setting.endpoints.getProject}?proposal_id=${villa?.proposal_id}`,
        "GET"
      );

      if (!response) {
        toast.error("Something went wrong");
        return;
      }

      if (!response.success) {
        toast.error(response.message);
        return;
      }

      if (!isArray(response.data)) {
        return response.data;
      }

      if (response.data.length === 0) {
        toast.error("No data found!");
        return;
      }
      setIsLoading(false);
      return response.data[0];
    } catch (error) {
      setIsLoading(false);
      toast.error(error.message);
    }
  }, [villa?.proposal_id]);

  useEffect(() => {
    if (villa?.proposal_status !== "Requested") return;

    getProjectDetails().then((projectDetails) => {
      const {
        project_type,
        name,
        description,
        username,
        customer_email,
        scope_of_work,
        project_image,
      } = projectDetails;

      const milestoneBudgets = projectDetails?.milestone?.flatMap(
        (milestone) => {
          if (!milestone.budget) return [];
          return milestone.budget;
        }
      );

      const transformedVilla = transformToVilla(villa?.proposal_id, {
        project_type,
        name,
        description,
        username,
        customer_email,
        scope_of_work,
        project_image,
        milestones: projectDetails.milestone ?? [],
        budgets: milestoneBudgets ?? [],
        payment_groups: projectDetails.payment_groups ?? [],
      });

      setGeneratedVilla(transformedVilla);

      const summary = transformSummaryData(projectDetails ?? {});
      const milestones = transformMilestonesData(
        projectDetails?.milestone ?? [],
        projectDetails?.payment_groups ?? []
      );
      const budgets = transformBudgetsData(milestoneBudgets ?? []);
      const payment_groups = transformPaymentGroupsData(
        projectDetails?.payment_groups ?? []
      );

      dispatch(
        setProposalDetails({
          ...proposalDetails,
          ...summary,
          milestones,
          budgets,
          payment_group: payment_groups,
          savDraftProposalId: "",
          // isSummaryValid: tabValue > 0,
        })
      );
      dispatch(
        setErrors({
          ...errors,
          isSummaryValid: true,
          isMilestonesValid: true,
          isBudgetsValid: true,
          isPaymentGroupsValid: true,
        })
      );
    });
  }, [
    villa?.proposal_status,
    villa?.proposal_id,
    getProjectDetails,
    transformSummaryData,
    transformMilestonesData,
    transformBudgetsData,
    transformPaymentGroupsData,
    dispatch,
    setErrors,
    setProposalDetails,
  ]);

  useEffect(() => {
    if (villa?.proposal_status === "Requested") return;
    if (!isUpdateDraftProposal) return;

    const initializeProposalDetails = () => {
      setGeneratedVilla(villa);
      const summary = transformSummaryData(villa?.project ?? {});
      const milestones = transformMilestonesData(
        villa?.milestones ?? [],
        villa?.payment_groups ?? []
      );
      const budgets = transformBudgetsData(villa?.budgets ?? []);
      const payment_groups = transformPaymentGroupsData(
        villa?.payment_groups ?? []
      );

      dispatch(
        setProposalDetails({
          ...proposalDetails,
          ...summary,
          milestones,
          budgets,
          payment_group: payment_groups,
          savDraftProposalId: "",
        })
      );
      setTabValue(tabValue);
      dispatch(
        setErrors({
          ...errors,
          isSummaryValid: tabValue >= 0,
          isMilestonesValid: tabValue > 1,
          isBudgetsValid: tabValue > 2,
          isPaymentGroupsValid: tabValue > 3,
        })
      );
    };
    initializeProposalDetails();
  }, [
    isUpdateDraftProposal,
    villa,
    transformSummaryData,
    transformMilestonesData,
    transformBudgetsData,
    transformPaymentGroupsData,
  ]);

  /**
   * Collects image URLs from the proposalDetails.project_image array.
   * The function assumes that each item in the project_image array has an 'image' property containing the URL.
   *
   * @param {Array} projectImages - Array of project images, which will contain image objects with URLs.
   * @returns {Array} - Array of image URLs.
   */

  useEffect(() => {
    if (isCreateProposal) return;
    if (!isCreateProposal) {
      setGeneratedVilla(villa);
      // const projectImages = fetchImages();
      dispatch(
        setProposalDetails({
          ...proposalDetails,
          email: villa.customer_email ?? "",
          description: villa.description ?? "",
          end_date: villa.end_date ?? "",
          name: villa.name ?? "",
          project_type: villa.project_type ?? "",
          start_date: villa.start_date ?? "",
          customer_name: villa.username ?? "",
          // change this to project_image everywhere instead of project
          uploadedImages: villa?.project?.project_image,
          dimensions: JSON.parse(villa?.dimensions) ?? "",
          form_json: villa?.form_json ?? {},
          // project: villa.project_image ?? [],
        })
      );
    }
  }, []);

  // const handleDisableTab = (tabName) => {
  //   setDisableTabs((prev) => ({ ...prev, [tabName]: false }));
  // };

  async function imageUrlToBase64(url) {
    const data = await fetch(url);
    const blob = await data.blob();
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const base64data = reader.result;
        resolve(base64data);
      };
      reader.onerror = reject;
    });
  }
  const isTabDisabled = (key, valueType) => {
    if (valueType === "object") {
      return (
        !isObject(proposalDetails[key]) ||
        (isObject(proposalDetails[key]) && isEmpty(proposalDetails[key]))
      );
    }
    if (valueType === "array") {
      return (
        !isArray(proposalDetails[key]) ||
        (isArray(proposalDetails[key]) && !proposalDetails[key].length)
      );
    }
    return !proposalDetails[key];
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
        backgroundColor: "#F9F9FA",
      }}
    >
      {!isLoading ? (
        <>
          <div className={classes.title}>
            {isUpdateDraftProposal
              ? `${proposalDetails?.name}`
              : "Submit Proposal"}
          </div>
          <Grid
            container
            style={{ padding: isMobile && !isTablet ? "20px 0" : md ? 20 : 40 }}
            justifyContent={
              !isCreateProposal && !md ? "space-between" : "center"
            }
            flex={1}
            boxSizing={"border-box"}
          >
            <Grid
              item
              xs={isMobile ? 11 : 10}
              sm={10}
              md={isCreateProposal ? 10 : 7.8}
              xl={isCreateProposal ? 11 : 8}
              className={classes.MainContainer}
            >
              <Grid
                item
                container
                className={classes.contentContainer}
                id="scope"
                gap="28px"
                display="flex"
              >
                <Grid
                  item
                  flex={1}
                  xs={12}
                  style={{ borderBottom: "1px solid #F2F3F4" }}
                >
                  <Tabs
                    value={tabValue}
                    variant="scrollable"
                    onChange={(v, b) => {
                      setTabValue(b);
                    }}
                  >
                    <Tab label="Details" />
                    <Tab label="Milestones" disabled={!errors.isSummaryValid} />
                    <Tab label="Budget" disabled={!errors.isMilestonesValid} />
                    <Tab
                      label="Payment Group"
                      disabled={!errors.isBudgetsValid}
                    />
                    <Tab
                      label="Summary"
                      disabled={
                        !errors.isSummaryValid ||
                        !errors.isPaymentGroupsValid ||
                        !errors.isMilestonesValid ||
                        !errors.isBudgetsValid
                      }
                    />
                  </Tabs>
                </Grid>

                {tabValue === 0 ? (
                  <Summary
                    handleTabValue={(tabValue) => setTabValue(tabValue)}
                    villa={generatedVilla}
                    isCreateProposal={isCreateProposal}
                    classes={classes}
                    isDraft={isDraft}
                  />
                ) : null}
                {tabValue === 1 ? (
                  <Milestones
                    handleTabValue={(tabValue) => setTabValue(tabValue)}
                    villa={generatedVilla}
                    tabValue={tabValue}
                    isUpdateDraftProposal={isUpdateDraftProposal}
                  />
                ) : null}
                {tabValue === 2 ? (
                  <Budgets
                    handleTabValue={(tabValue) => setTabValue(tabValue)}
                    villa={generatedVilla}
                    isUpdateDraftProposal={isUpdateDraftProposal}
                  />
                ) : null}
                {tabValue === 3 ? (
                  <PaymentGroup
                    handleTabValue={(tabValue) => setTabValue(tabValue)}
                    villa={generatedVilla}
                    isUpdateDraftProposal={isUpdateDraftProposal}
                  />
                ) : null}
                {tabValue === 4 ? (
                  <Details
                    handleTabValue={(tabValue) => setTabValue(tabValue)}
                    villa={generatedVilla}
                    isUpdateDraftProposal={isUpdateDraftProposal}
                  />
                ) : null}
                <TabActions
                  tabValue={tabValue}
                  onBackButtonClick={() => setTabValue(tabValue - 1)}
                  onNextButtonClick={() => setTabValue(tabValue + 1)}
                />
              </Grid>
            </Grid>
            {!isCreateProposal && (
              <Grid
                item
                container
                xs={isMobile ? 11 : 10}
                sm={10}
                md={4}
                xl={3.8}
                className={classes.MainContainer}
                style={{ padding: "12px", height: "fit-content" }}
              >
                <ProposalCard villa={villa} />
              </Grid>
            )}
          </Grid>
          <BlueAbout />
        </>
      ) : (
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "20px 0",
          }}
        >
          <CircularProgress size={40} />
        </div>
      )}
    </div>
  );
};
export default CreateProposal;
