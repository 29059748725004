import _, { isArray } from "lodash";
import { store } from "../redux/store/configureStore";
import authAction from "../redux/reducers/auth/actions";
import { Setting } from "./Setting";

function getUserToken() {
  const {
    auth: { token },
  } = store.getState();

  return `Bearer ${token}`;
}

function isJson(response) {
  const contentType = response.headers.get("content-type");
  return contentType && contentType.indexOf("application/json") !== -1;
}

export function getApiData(
  endpoint,
  method,
  data,
  headers,
  isAlternateUrl = false
) {
  const isOnline = window.navigator.onLine;
  if (isOnline) {
    return new Promise((resolve, reject) => {
      let query = "";
      let qs = "";

      const params = {};
      params.method = method.toLowerCase() || "post";

      if (headers) {
        const obj = headers;
        obj["cache-control"] =
          "no-store, no-cache, must-revalidate, post-check=0, pre-check=0";

        params.headers = obj;
      } else {
        params.headers = {
          "Content-Type": "application/json",
        };
      }

      const token = getUserToken();
      if (token) {
        params.headers.Authorization = token;
      }

      if (params.method === "post" || params.method === "put") {
        if (
          params.headers &&
          params.headers["Content-Type"] &&
          params.headers["Content-Type"] === "application/json"
        ) {
          params.body = JSON.stringify(data);
        } else {
          params.body = query;
        }
      } else {
        qs = `?${query}`;
      }

      let url = isAlternateUrl
        ? Setting.newBaseUrl + endpoint + qs
        : Setting.api + endpoint + qs;
      let length = url.length;
      if (url.charAt(length - 1) === "?") url = url.slice(0, length - 1);
      fetch(url, params)
        .then((response) => {
          if (response.ok) {
            if (response.status === 204) {
              return null; // No content
            }
            return isJson(response) ? response.json() : response.text();
          }
          return response.json().then((err) => {
            throw new Error(err.message || 'Unexpected error');
          });
        })
        .then((resposeJson) => {
          if (
            _.isObject(resposeJson) &&
            _.has(resposeJson, "code") &&
            (_.toNumber(resposeJson.code) === 403 ||
              _.toNumber(resposeJson.code) === 401 ||
              _.toNumber(resposeJson.status) === 403 ||
              _.toNumber(resposeJson.status) === 401 ||
              _.toNumber(resposeJson.code) === 404 ||
              _.toNumber(resposeJson.status) === 404)
          ) {
            store.dispatch(authAction.clearAllData());
          } else {
            resolve(resposeJson);
          }
        })
        .catch((err) => {
          console.log("Catch Part", err);
          reject(err);
        });
    });
  }
}

export function getAPIProgressData(
  endpoint,
  method,
  data,
  headers = false,
  onProgress = null,
  isAlternateUrl = false

) {
  const isOnline = window.navigator.onLine;
  if (isOnline) {
    return new Promise(async (resolve, reject) => {
      let url = isAlternateUrl
        ? Setting.newBaseUrl + endpoint
        : Setting.api + endpoint;

      // const url = Setting.api + endpoint;
      const oReq = new XMLHttpRequest();
      oReq.upload.addEventListener("progress", (event) => {
        if (event.lengthComputable) {
          const progress = (event.loaded * 100) / event.total;
          if (onProgress) {
            onProgress(progress);
          }
        }
      });

      var FormData = require("form-data");
      var form = new FormData();
      if (data && Object.keys(data).length > 0) {
        Object.keys(data).map((k) => {
          if (isArray(data[k]) && data[k].length > 0) {
            data[k].map((item) => {
              form.append(k, item);
            });
          } else {
            form.append(k, data[k]);
          }
        });
      }

      const hData = {
        "Content-Type": "multipart/form-data",
      };

      if (headers) {
        hData.Authorization = getUserToken();
      }

      let options = {
        method: method,
        headers: hData,
        body: form,
      };

      delete options.headers["Content-Type"];

      fetch(url, options)
        .then(function (res) {
          if (
            res.status === 403 ||
            res.status === 401 ||
            res.status === 404 ||
            res.code === 403 ||
            res.code === 401 ||
            res.code === 404
          ) {
            store.dispatch(authAction.clearAllData());
          } else {
            resolve(res.json());
          }
        })
        .then(function (result) {})
        .catch((err) => {
          console.log("Catch Part", err);
          reject(err);
        });
    });
  }
}
export function postFormData(
  endpoint,
  data,
  headers = {},
  isAlternateUrl = false
) {
  const isOnline = window.navigator.onLine;
  if (isOnline) {
    return new Promise((resolve, reject) => {
      const params = {};
      params.method = "POST";

      // Set headers, ensuring not to set Content-Type for FormData
      if (headers) {
        params.headers = headers;
      }

      const token = getUserToken();
      if (token) {
        if (!params.headers) {
          params.headers = {};
        }
        params.headers.Authorization = token;
      }

      // Check if data is instance of FormData
      if (data instanceof FormData) {
        params.body = data;
        // Do not set Content-Type header for FormData, it will be set automatically
      } else {
        params.headers["Content-Type"] = "application/json";
        params.body = JSON.stringify(data);
      }

      let url = isAlternateUrl
        ? Setting.newBaseUrl + endpoint
        : Setting.api + endpoint;

      fetch(url, params)
        .then((response) => {
          if (response.ok) {
            if (response.status === 204) {
              return null; // No content
            }
            return response.json();
          }
          return response.json().then((err) => {
            throw new Error(err.message || 'Unexpected error');
          });
        })
        .then((responseJson) => {
          if (
            _.isObject(responseJson) &&
            _.has(responseJson, "code") &&
            (_.toNumber(responseJson.code) === 403 ||
              _.toNumber(responseJson.code) === 401 ||
              _.toNumber(responseJson.status) === 403 ||
              _.toNumber(responseJson.status) === 401 ||
              _.toNumber(responseJson.code) === 404 ||
              _.toNumber(responseJson.status) === 404)
          ) {
            store.dispatch(authAction.clearAllData());
          } else {
            resolve(responseJson);
          }
        })
        .catch((err) => {
          console.log("Catch Part", err);
          reject(err);
        });
    });
  }
}
