import {
  CircularProgress,
  Collapse,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Setting } from "../../../../utils/Setting";
import { getApiData } from "../../../../utils/APIHelper";
import { toast } from "react-toastify";
import useStyles from "../styles";
import { color } from "../../../../config/theme";
import BudgetItem from "./BudgetItem";
import moment from "moment";

const ChangeRequestDetails = ({ open, changeRequestId }) => {
  const [state, setState] = useState();
  const [loading, setLoading] = useState();

  const classes = useStyles();

  const fetchDetails = async () => {
    try {
      setLoading(true);
      const data = await getApiData(
        Setting.endpoints.changeRequest.details(changeRequestId),
        "GET",
        {},
        {},
        true
      );
      setState(data);
    } catch (err) {
      toast.error(err.message || "Something went wrong!");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (changeRequestId && open) {
      fetchDetails();
    }
  }, [changeRequestId, open]);

  if (!open) {
    return;
  }

  return (
    <Stack>
      {loading !== false ? (
        <div className={classes.loadingContainer}>
          <CircularProgress style={{ color: color.primary }} />
        </div>
      ) : !state ? (
        <Stack minHeight="200px" justifyContent="center" alignItems="center">
          <Typography variant="h5" fontFamily="Poppins-Regular">
            No Data Found
          </Typography>
        </Stack>
      ) : (
        <Stack width="100%" className={classes.budgetContainer}>
          <span className={classes.sectionTitle}>New Milestone Details</span>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "20px",
              alignItems: "flex-start",
            }}
          >
            <div
              style={{
                width: "160px",
                height: "140px",
              }}
            >
              <img
                style={{ borderRadius: "14px" }}
                width="100%"
                height="100%"
                src={
                  Object.keys(
                    state?.newMilestoneDetails?.proposalMilestone?.file || {}
                  ).length === 0
                    ? "https://renohome.blob.core.windows.net/reno-cms/e56d3d53-e335-425f-990e-16e6b2bbee1b"
                    : state?.newMilestoneDetails?.proposalMilestone?.file
                        ?.fileUrl
                }
                alt="placeholder"
              />
            </div>
            <Stack width="100%" gap="10px" paddingTop={1}>
              <Stack
                direction="row"
                display="flex"
                gap="20px"
                width="100%"
                flexGrow={1}
              >
                <Stack direction="row" gap="10px" alignItems="center">
                  <span className={classes.key}>Milestone Name:</span>
                  {state?.newMilestoneDetails?.proposalMilestone?.milestoneName}
                </Stack>
              </Stack>
              <Stack direction="row" gap="10px" alignItems="flex-start">
                <span className={classes.key}>Description:</span>
                <span className={classes.description}>
                  {state?.newMilestoneDetails?.proposalMilestone?.description}
                </span>
              </Stack>
              <Stack
                direction="row"
                display="flex"
                gap="20px"
                width="100%"
                flexGrow={1}
              >
                <Stack direction="row" gap="10px" alignItems="center">
                  <span className={classes.key}>Start Date:</span>
                  {moment(
                    state?.newMilestoneDetails?.proposalMilestone?.startDate
                  ).format("MMM DD, yyyy")}
                </Stack>
                <Divider orientation="vertical" flexItem />
                <Stack direction="row" gap="10px" alignItems="center">
                  <span className={classes.key}>End Date:</span>
                  {moment(
                    state?.newMilestoneDetails?.proposalMilestone?.endDate
                  ).format("MMM DD, yyyy")}
                </Stack>
              </Stack>
            </Stack>
          </div>
          {state?.newMilestoneDetails?.proposalBudgetItems?.map((budget) => (
            <BudgetItem isNew={false} data={budget} />
          ))}
          {!!state?.oldMilestoneDetails && (
            <>
              <Divider />
              <span className={classes.sectionTitle}>
                Old Milestone Details
              </span>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "20px",
                  alignItems: "flex-start",
                }}
              >
                <div
                  style={{
                    width: "160px",
                    height: "140px",
                  }}
                >
                  <img
                    style={{ borderRadius: "14px" }}
                    width="100%"
                    height="100%"
                    src={
                      Object.keys(
                        state?.oldMilestoneDetails?.proposalMilestone?.file ||
                          {}
                      ).length === 0
                        ? "https://renohome.blob.core.windows.net/reno-cms/e56d3d53-e335-425f-990e-16e6b2bbee1b"
                        : state?.oldMilestoneDetails?.proposalMilestone?.file
                            ?.fileUrl
                    }
                    alt="placeholder"
                  />
                </div>
                <Stack width="100%" gap="10px" paddingTop={1}>
                  <Stack
                    direction="row"
                    display="flex"
                    gap="20px"
                    width="100%"
                    flexGrow={1}
                  >
                    <Stack direction="row" gap="10px" alignItems="center">
                      <span className={classes.key}>Milestone Name:</span>
                      {
                        state?.oldMilestoneDetails?.proposalMilestone
                          ?.milestoneName
                      }
                    </Stack>
                  </Stack>
                  <Stack direction="row" gap="10px" alignItems="center">
                    <span className={classes.key}>Description:</span>
                    {state?.oldMilestoneDetails?.proposalMilestone?.description}
                  </Stack>
                  <Stack
                    direction="row"
                    display="flex"
                    gap="20px"
                    width="100%"
                    flexGrow={1}
                  >
                    <Stack direction="row" gap="10px" alignItems="center">
                      <span className={classes.key}>Start Date:</span>
                      {moment(
                        state?.oldMilestoneDetails?.proposalMilestone?.startDate
                      ).format("MMM DD, yyyy")}
                    </Stack>
                    <Divider orientation="vertical" flexItem />
                    <Stack direction="row" gap="10px" alignItems="center">
                      <span className={classes.key}>End Date:</span>
                      {moment(
                        state?.oldMilestoneDetails?.proposalMilestone?.endDate
                      ).format("MMM DD, yyyy")}
                    </Stack>
                  </Stack>
                </Stack>
              </div>

              {state?.oldMilestoneDetails?.proposalBudgetItems?.map(
                (budget) => (
                  <BudgetItem data={budget} />
                )
              )}
            </>
          )}
        </Stack>
      )}
    </Stack>
  );
};

export default ChangeRequestDetails;
