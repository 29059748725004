import types from "./actions";

const initialProposalDetails = {
  email: "",
  name: "",
  customer_name: "",
  project_type: "",
  exp_id: "",
  description: "",
  scope_of_work: "",
  project_image: [],
  milestones: {},
  budgets: {},
  payment_group: {},
  uploadedImages: [] // the images object that come from the backend to display its url
};
const initialErrorsValues = {
  summaryErrors: {
    scpErr: false,
    scpMsg: "",
    typeErr: false,
    typeMsg: "",
    nameErr: false,
    nameMsg: "",
    cNameErr: false,
    cNameMsg: "",
    descriptionErr: false,
    descriptionMsg: "",
    emailErr: false,
    emailMsg: "",
    documentErr: false,
    documentMsg: "",
    startErr: false,
    startMsg: "",
    endErr: false,
    endMsg: "",
  },
  isSummaryValid: false,
  isMilestonesValid: false,
  isBudgetsValid: false,
  isPaymentGroupsValid: false
}

const initialState = {
  userData: {},
  cometChatUserdata: {},
  token: "",
  accountTab: 0,
  proposalDetails: initialProposalDetails,
  useruuid: "",
  isNotify: false,
  notiData: {},
  errors: initialErrorsValues
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types.SET_USER_DATA:
      localStorage.setItem("userData", JSON.stringify(action.userdata));
      return {
        ...state,
        userData: action.userdata,
      };

    case types.SET_COMETCHAT_USER_DATA:
      return {
        ...state,
        cometChatUserdata: action.cometChatUserdata,
      };

    case types.PROPOSAL_DETAILS:
      return {
        ...state,
        proposalDetails: action.proposalDetails,
      };

    case types.RESET_PROPOSAL_DETAILS:
      return {
        ...state,
        proposalDetails: initialProposalDetails,
      };
    case types.RESET_ERRORS:
      return {
        ...state,
        errors: initialErrorsValues,
      };
    case types.SET_ERRORS:
      return {
        ...state,
        errors: action.errors,
      };

    case types.SET_TOKEN:
      return {
        ...state,
        token: action.token,
      };

    case types.SET_ACCOUNT_TAB:
      return {
        ...state,
        accountTab: action.accountTab,
      };

    case types.SET_USER_UUID:
      return {
        ...state,
        useruuid: action.useruuid,
      };

    case types.SET_DISPLAY_NOTIFICATION_POP_UP:
      return {
        ...state,
        isNotify: action.isNotify,
      };

    case types.SET_NOTI_DATA:
      return {
        ...state,
        notiData: action.notiData,
      };

    case types.CLEAR_ALL_STORAGE_DATA:
      // localStorage.clear();
      localStorage.removeItem("userData", "token");
      return {
        ...state,
        userData: {},
        token: "",
        accountTab: 0,
        useruuid: "",
        proposalDetails: {},
      };

    default:
      return state;
  }
}
