import {
  Box,
  Button,
  Divider,
  Fade,
  FormHelperText,
  Grid,
  InputLabel,
  Menu,
  MenuItem,
  Modal,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useMemo, useRef, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import theme from "../../../config/theme";
import moment from "moment";
import { Close, HighlightOffOutlined } from "@mui/icons-material";
import CInput from "../../../components/CInput";
import CAutocomplete from "../../../components/CAutocomplete";
import Images from "../../../config/images";
import { toast } from "react-toastify";
import authActions from "../../../redux/reducers/auth/actions";
import _, { isArray, isEmpty, isNull } from "lodash";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import SingleMilestoneAccordion from "../../../components/SingleMilestoneAccordian";
import SingleBudgetAccordion from "../../../components/SingleBudgetAccordian";
import ConfirmModel from "../../../components/ConfirmModel";
// import { getAPIProgressData } from "../../../utils/APIHelper";
import { formattedAmount } from "../../../utils/CommonFunction";
import { MATERIAL_UNITS } from "../../../config/constants";
// import { Setting } from "../../../utils/Setting";
// import { getSaveDraftPayload } from "../helpers";

const errorObj = {
  bNameErr: false,
  bNameMsg: "",
  materialTypeErr: false,
  materialTypeMsg: "",
  materialUnitPriceErr: false,
  materialUnitPriceMsg: "",
  quantityErr: false,
  quantityMsg: "",
  unitErr: false,
  unitMsg: "",
  daysErr: false,
  daysMsg: "",
  manpowerRateErr: false,
  manpowerRateMsg: "",
  manpowerMilestoneErr: false,
  manpowerMilestoneMsg: "",
  specificationsErr: false,
  specificationsMsg: "",
  photoErr: false,
  photoMsg: "",
};

const initialFormvalues = {
  name: "",
  photo_url: [],
  photo_origin: [],
  material_type: "",
  material_unit: "",
  material_unit_price: "",
  qty: "",
  manpower_rate: "",
  days: "",
  specification: "",
  updatedAt: moment().format("MMM DD, YYYY"),
};

const Budgets = ({ handleTabValue, handleDisableTab ,isUpdateDraftProposal}) => {
  // const navigate = useNavigate();
  const dispatch = useDispatch();
  // const location = useLocation();
  // const villa = location?.state?.villa ? location?.state?.villa : {};
  // const isUpdateDraftProposal = Boolean(location?.state?.isUpdateDraftProposal);
  const { proposalDetails, errors } = useSelector((state) => state.auth);
  // const [milestones, setMilestones] = useState(proposalDetails.milestones);
  const { setProposalDetails, setErrors } = authActions;
  const fileInputRef = useRef();
  const md = useMediaQuery(theme.breakpoints.down("md"));
  const sm = useMediaQuery(theme.breakpoints.down("sm"));
  // const [isOpen, setIsOpen] = useState(false);
  // const [budgets, setBudgets] = useState({});
  const [isCreationOpen, setIsCreationOpen] = useState(false);
  const [visibleEditModal, setVisibleEditModal] = useState(false);
  const [budgetDetails, setBudgetDetails] = useState(initialFormvalues);
  const [errObj, setErrObj] = useState(errorObj);
  const [anchorEl, setAnchorEl] = useState(null);
  const [visible, setVisible] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedBudget, setSelectedBudget] = useState({});

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: sm ? 300 : 500,
    height: sm ? 300 : 500,
    bgcolor: "background.paper",
    borderRadius: 1,
    boxShadow: 24,
    padding: "24px",
    overflow: "auto",
  };


  const totalBudgetAmount = useMemo(() => {
    const amount = Object.keys(proposalDetails.milestones).reduce(
      (acc, key) => {
        let milestone = proposalDetails.milestones[key];
        return acc + parseFloat(milestone.amount ?? 0);
      },
      0
    );
    return amount;
  }, [proposalDetails.milestones]);

  // const updatePaymentGroup = useCallback(() => {
  //   const paymentGroup = proposalDetails.payment_group;
  //   const newPaymentGroup = {};
  //   for (let key in paymentGroup) {
  //     const test = paymentGroup[key];
  //     const newGroup = {};
  //     for (let id in milestones) {
  //       const milestone = milestones[id];
  //       if (milestone.group_id == key) {
  //         newGroup[id] = milestone;
  //       }
  //     }

  //     if (Object.keys(newGroup).length) {
  //       newPaymentGroup[key] = {
  //         groupName: test.groupName,
  //         dueDate: test.dueDate,
  //         group: newGroup,
  //       };
  //     }
  //   }
  //   return newPaymentGroup;
  // }, [milestones, proposalDetails.payment_group]);
  // set budgets
  // useEffect(() => {
  //   if (proposalDetails.budgets) {
  //     setBudgets(proposalDetails.budgets);
  //   }
  // }, [proposalDetails.budgets]);

  // set every milestone total amount
  useEffect(() => {
    let milestones = { ...proposalDetails.milestones };
    Object.keys(proposalDetails.milestones).map((milestoneKey) => {
      const milestone = proposalDetails.milestones[milestoneKey];
      let totalAmount = Object.keys(proposalDetails.budgets).reduce(
        (acc, budgetKey) => {
          const budget = proposalDetails.budgets[budgetKey];
          if (budget.milestone_id == milestoneKey) {
            return (acc += parseFloat(budget.amount));
          }
          return (acc += 0);
        },
        0
      );
      const budgetIds = Object.keys(proposalDetails.budgets).filter(
        (budgetKey) => {
          const budget = proposalDetails.budgets[budgetKey];
          if (budget.milestone_id == milestoneKey) {
            return budgetKey;
          }
        }
      );
      // let milestones = { ...proposalDetails.milestones };

      milestones[milestoneKey] = {
        ...milestone,
        amount: totalAmount,
        budgets: budgetIds,
      };
      // dispatch(setProposalDetails({
      //   ...proposalDetails,
      //   milestones
      // }));

      // setMilestones((prev) => ({
      //   ...prev,
      //   [milestoneKey]: {
      //     ...milestone,
      //     amount: totalAmount,
      //     budgets: budgetIds,
      //   },
      // }));
    });
    dispatch(
      setProposalDetails({
        ...proposalDetails,
        milestones,
      })
    );
  }, [proposalDetails.budgets]);

  useEffect(() => {
    // const dates = calculateDates(proposalDetails?.milestones);
    if (proposalDetails.payment_group) {
      const paymentGroups = proposalDetails.payment_group;
      const newPaymentGroup = {};
      for (let key in paymentGroups) {
        const test = paymentGroups[key];
        const newGroup = {};
        let latestEndDate;
        for (let id in proposalDetails.milestones) {
          const milestone = proposalDetails.milestones[id];
          if (milestone.group_id == key) {
            newGroup[id] = milestone;
            const milestoneEndDate = new Date(milestone.end_date);
            if (!latestEndDate || milestoneEndDate > latestEndDate) {
              latestEndDate = milestoneEndDate;
            }
          }
        }

        if (Object.keys(newGroup).length) {
          newPaymentGroup[key] = {
            groupName: test.groupName,
            dueDate: latestEndDate ? latestEndDate : test.dueDate,
            group: newGroup,
          };
        }
      }
      dispatch(
        setProposalDetails({
          ...proposalDetails,
          // start_date: dates.startDate,
          // end_date: dates.endDate,
          payment_group: newPaymentGroup,
        })
      );
    }
  }, [proposalDetails.milestones]);

  function clearData() {
    setBudgetDetails(initialFormvalues);
    setErrObj(errorObj);
  }

  function clearErr() {
    setErrObj({
      ...errObj,
      materialTypeErr: false,
      materialTypeMsg: "",
      materialUnitPriceErr: false,
      materialUnitPriceMsg: "",
      quantityErr: false,
      quantityMsg: "",
      unitErr: false,
      unitMsg: "",
      daysErr: false,
      daysMsg: "",
      manpowerRateErr: false,
      manpowerRateMsg: "",
    });
  }

  // const handleConfirm = () => {
  //   setIsOpen(false);
  //   dispatch(setProposalDetails({}));
  //   navigate("/dashboard");
  // };

  const handleCloseCreation = () => {
    setIsCreationOpen(false);
    setVisible(false);
    setVisibleEditModal(false);
    clearData();
  };

  function checkImgSize(img) {
    let valid = true;
    if (img.size > 3145728) {
      valid = false;
    } else {
      valid = true;
    }
    return valid;
  }

  function displayImagesView(mode) {
    if (
      isArray(budgetDetails.photo_origin) &&
      budgetDetails?.photo_origin?.length > 0
    ) {
      if (mode === "form" && visibleEditModal) {
        return null;
      } else {
        return budgetDetails?.photo_origin?.map((item, index) => {
          let imgUrl = "";
          if (item.image) {
            imgUrl = item.image;
          } else if (typeof item === "object" && item instanceof Blob) {
            imgUrl = URL.createObjectURL(item);
          } else {
            imgUrl = item;
          }
          return (
            <div
              style={{
                display: "flex",
                border: "1px solid #F2F3F4",
                borderRadius: 6,
                marginBottom: 5,
                padding: 3,
              }}
            >
              <img
                style={{
                  width: 60,
                  height: 70,
                  borderRadius: 6,
                  marginRight: 20,
                  objectFit: "cover",
                }}
                src={imgUrl}
                alt="Budget Photos"
              />
              <div style={{ margin: "auto 0" }}>
                <Typography
                  style={{
                    fontFamily: "Poppins-Regular",
                    fontWeight: "500",
                    color: "#202939",
                    fontSize: 18,
                  }}
                >
                  {item?.name || `Budget Image ${index + 1}` || ""}
                </Typography>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginLeft: "auto",
                  marginRight: 10,
                }}
              >
                <HighlightOffOutlined
                  style={{
                    zIndex: 10,
                    cursor: "pointer",
                    fontSize: 28,
                    color: "#8C92A4",
                  }}
                  onClick={() => {
                    const nArr = [...budgetDetails.photo_origin];
                    nArr.splice(index, 1);
                    setBudgetDetails({
                      ...budgetDetails,
                      photo_origin: nArr,
                    });
                  }}
                />
              </div>
            </div>
          );
        });
      }
    }
  }

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => {
        resolve(reader.result);
      };

      reader.onerror = (error) => {
        reject(error);
      };
    });
  };

  async function UploadFileDirectly(img) {
    const nArr1 = budgetDetails.photo_origin
      ? [...budgetDetails.photo_origin]
      : [];
    for (let i = 0; i < img.length; i++) {
      const base64Data = await convertToBase64(img[i]);
      nArr1.push(base64Data);
    }
    setBudgetDetails({ ...budgetDetails, photo_origin: nArr1 });

    setErrObj({
      ...errObj,
      photoErr: false,
      photoMsg: "",
    });
  }

  function renderBudgetCreateForm(mode) {
    return (
      <Modal open={visible || isCreationOpen} onClose={handleCloseCreation} >
        <Fade in={visible || isCreationOpen} style={{height:" auto"}}>
          <Box sx={style}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              {mode === "modal" ? (
                <div className="addMilestoneHeader">Update Budget Item</div>
              ) : (
                <div className="addMilestoneHeader">Add Budget Item</div>
              )}
              <Close
                style={{ cursor: "pointer" }}
                onClick={() => handleCloseCreation()}
              />
            </div>
            <div
              style={{
                marginTop: 12,

                height: 1,
                width: "100%",
                background: "#EEF0F3",
              }}
            />

            <Grid item xs={12} id="bName" mt={2} marginBottom={1}>
              <CInput
                label={<span className="fieldTitle">Budget Item Name</span>}
                placeholder="Enter Budget Name..."
                value={
                  mode === "modal" && visibleEditModal
                    ? budgetDetails.name
                    : mode === "form" && visibleEditModal
                    ? ""
                    : budgetDetails.name
                }
                onChange={(e) => {
                  setBudgetDetails({ ...budgetDetails, name: e.target.value });
                  setErrObj({
                    ...errObj,
                    bNameErr: false,
                    bNameMsg: "",
                  });
                }}
                inputProps={{ maxLength: 50 }}
                error={
                  mode === "modal" && visibleEditModal
                    ? errObj.bNameErr
                    : mode === "form" && visibleEditModal
                    ? ""
                    : errObj.bNameErr
                }
                helpertext={
                  mode === "modal" && visibleEditModal
                    ? errObj.bNameMsg
                    : mode === "form" && visibleEditModal
                    ? ""
                    : errObj.bNameMsg
                }
              />
            </Grid>

            <Grid item xs={12} id="material_type" marginBottom={1}>
              <CInput
                label={<span className="fieldTitle">Material type:</span>}
                placeholder="marble, wood, etc..."
                value={
                  mode === "modal" && visibleEditModal
                    ? budgetDetails.material_type
                    : mode === "form" && visibleEditModal
                    ? ""
                    : budgetDetails.material_type
                }
                onChange={(e) => {
                  setBudgetDetails({
                    ...budgetDetails,
                    material_type: e.target.value,
                  });
                  clearErr();
                }}
                inputProps={{ maxLength: 50 }}
                error={
                  mode === "modal" && visibleEditModal
                    ? errObj.materialTypeErr
                    : mode === "form" && visibleEditModal
                    ? ""
                    : errObj.materialTypeErr
                }
                helpertext={
                  mode === "modal" && visibleEditModal
                    ? errObj.materialTypeMsg
                    : mode === "form" && visibleEditModal
                    ? ""
                    : errObj.materialTypeMsg
                }
              />
            </Grid>

            <Grid item container columnGap={1} wrap={md ? "wrap" : "nowrap"} marginBottom={1}>
              <Grid item xs={12} md={4} id="Unit">
                <CAutocomplete
                  label={<span className="fieldTitle">Material unit:</span>}
                  placeholder="Enter material unit"
                  value={
                    mode === "modal" && visibleEditModal
                      ? budgetDetails.material_unit
                      : mode === "form" && visibleEditModal
                      ? ""
                      : budgetDetails.material_unit
                  }
                  onChange={(e, newValue) => {
                    setBudgetDetails({
                      ...budgetDetails,
                      material_unit: newValue,
                    });
                    clearErr();
                  }}
                  options={MATERIAL_UNITS}
                  getOptionLabel={(option) => option}
                  error={
                    mode === "modal" && visibleEditModal
                      ? errObj.unitErr
                      : mode === "form" && visibleEditModal
                      ? ""
                      : errObj.unitErr
                  }
                  helpertext={
                    mode === "modal" && visibleEditModal
                      ? errObj.unitMsg
                      : mode === "form" && visibleEditModal
                      ? ""
                      : errObj.unitMsg
                  }
                />
              </Grid>
              <Grid item xs={12} md={4} id="price">
                <CInput
                  label={
                    <span className="fieldTitle">Material unit price</span>
                  }
                  placeholder="Enter amount...."
                  value={
                    mode === "modal" && visibleEditModal
                      ? budgetDetails.material_unit_price
                      : mode === "form" && visibleEditModal
                      ? ""
                      : budgetDetails.material_unit_price
                  }
                  // type="number"
                  // onChange={(e) => {
                  //   const bool = /^[0-9]+(?:\.[0-9]+)?$/.test(
                  //     Number(e.target.value)
                  //   );
                  //   if (bool) {
                  //     setBudgetDetails({
                  //       ...budgetDetails,
                  //       material_unit_price: e.target.value,
                  //     });
                  //   }
                  //   clearErr();
                  // }}
                  type="tel"
                  onChange={(e) => {
                    const regex = /^\d*\.?\d{0,2}$/; // Allow numbers with up to 2 decimal places
                    if (regex.test(e.target.value)) {
                      setBudgetDetails({
                        ...budgetDetails,
                        material_unit_price: e.target.value,
                      });
                    }
                    clearErr();
                  }}
                  inputProps={{
                    pattern: "[0-9]*", // Allow only digits
                    inputMode: "decimal", // Show numeric keyboard on mobile devices with decimal option
                  }}
                  error={
                    mode === "modal" && visibleEditModal
                      ? errObj.materialUnitPriceErr
                      : mode === "form" && visibleEditModal
                      ? ""
                      : errObj.materialUnitPriceErr
                  }
                  helpertext={
                    mode === "modal" && visibleEditModal
                      ? errObj.materialUnitPriceMsg
                      : mode === "form" && visibleEditModal
                      ? ""
                      : errObj.materialUnitPriceMsg
                  }
                />
              </Grid>
              <Grid item xs={12} md={4} id="qty">
                <CInput
                  label={<span className="fieldTitle" >Quantity</span>}
                  placeholder="N/A"
                  value={
                    mode === "modal" && visibleEditModal
                      ? budgetDetails.qty
                      : mode === "form" && visibleEditModal
                      ? ""
                      : budgetDetails.qty
                  }
                  // type="tel"
                  // onChange={(e) => {
                  //   const bool = /^[0-9]+$/.test(Number(e.target.value));
                  //   if (bool) {
                  //     setBudgetDetails({
                  //       ...budgetDetails,
                  //       qty: e.target.value,
                  //     });
                  //   }
                  //   clearErr();
                  // }}
                  // inputProps={{
                  //   pattern: "[0-9]*", // Allow only digits
                  //   inputMode: "numeric", // Show numeric keyboard on mobile devices
                  // }}
                  type="tel"
                  onChange={(e) => {
                    const regex = /^\d*\.?\d{0,2}$/; // Allow numbers with up to 2 decimal places
                    if (regex.test(e.target.value)) {
                      setBudgetDetails({
                        ...budgetDetails,
                        qty: e.target.value,
                      });
                    }
                    clearErr();
                  }}
                  inputProps={{
                    pattern: "[0-9]*", // Allow only digits
                    inputMode: "decimal", // Show numeric keyboard on mobile devices with decimal option
                  }}
                  error={
                    mode === "modal" && visibleEditModal
                      ? errObj.quantityErr
                      : mode === "form" && visibleEditModal
                      ? ""
                      : errObj.quantityErr
                  }
                  helpertext={
                    mode === "modal" && visibleEditModal
                      ? errObj.quantityMsg
                      : mode === "form" && visibleEditModal
                      ? ""
                      : errObj.quantityMsg
                  }
                />
              </Grid>
            </Grid>

            <Grid item container columnGap={1} wrap={md ? "wrap" : "nowrap"} marginBottom={1}>
              <Grid item xs={12} md={4} id="rate">
                <CInput
                  label={<span className="fieldTitle">Manpower rate</span>}
                  placeholder="Enter amount...."
                  value={
                    mode === "modal" && visibleEditModal
                      ? budgetDetails.manpower_rate
                      : mode === "form" && visibleEditModal
                      ? ""
                      : budgetDetails.manpower_rate
                  }
                  type="number"
                  onChange={(e) => {
                    const bool = /^[0-9]+(?:\.[0-9]+)?$/.test(
                      Number(e.target.value)
                    );
                    if (bool) {
                      setBudgetDetails({
                        ...budgetDetails,
                        manpower_rate: e.target.value,
                      });
                    }
                    clearErr();
                  }}
                  error={
                    mode === "modal" && visibleEditModal
                      ? errObj.manpowerRateErr
                      : mode === "form" && visibleEditModal
                      ? ""
                      : errObj.manpowerRateErr
                  }
                  helpertext={
                    mode === "modal" && visibleEditModal
                      ? errObj.manpowerRateMsg
                      : mode === "form" && visibleEditModal
                      ? ""
                      : errObj.manpowerRateMsg
                  }
                />
              </Grid>

              <Grid item xs={12} md={4} id="days">
                <CInput
                  label={<span className="fieldTitle">Days</span>}
                  placeholder="N/A"
                  value={
                    mode === "modal" && visibleEditModal
                      ? budgetDetails.days
                      : mode === "form" && visibleEditModal
                      ? ""
                      : budgetDetails.days
                  }
                  type="tel"
                  onChange={(e) => {
                    const bool = /^[0-9]+$/.test(Number(e.target.value));
                    if (bool) {
                      setBudgetDetails({
                        ...budgetDetails,
                        days: e.target.value,
                      });
                    }
                    clearErr();
                  }}
                  inputProps={{
                    pattern: "[0-9]*", // Allow only digits
                    inputMode: "numeric", // Show numeric keyboard on mobile devices
                  }}
                  error={
                    mode === "modal" && visibleEditModal
                      ? errObj.daysErr
                      : mode === "form" && visibleEditModal
                      ? ""
                      : errObj.daysErr
                  }
                  helpertext={
                    mode === "modal" && visibleEditModal
                      ? errObj.daysMsg
                      : mode === "form" && visibleEditModal
                      ? ""
                      : errObj.daysMsg
                  }
                />
              </Grid>
            </Grid>

            <Grid item xs={12} id="description" marginBottom={1}>
              <CInput
                multiline={true}
                rows={2}
                label={<span className="fieldTitle">Specifications:</span>}
                placeholder="Write here..."
                value={
                  mode === "modal" && visibleEditModal
                    ? budgetDetails.specification
                    : mode === "form" && visibleEditModal
                    ? ""
                    : budgetDetails.specification
                }
                onChange={(e) => {
                  setBudgetDetails({
                    ...budgetDetails,
                    specification: e.target.value,
                  });
                  setErrObj({
                    ...errObj,
                    specificationsErr: false,
                    specificationsMsg: "",
                  });
                }}
                error={
                  mode === "modal" && visibleEditModal
                    ? errObj.specificationsErr
                    : mode === "form" && visibleEditModal
                    ? ""
                    : errObj.specificationsErr
                }
                helpertext={
                  mode === "modal" && visibleEditModal
                    ? errObj.specificationsMsg
                    : mode === "form" && visibleEditModal
                    ? ""
                    : errObj.specificationsMsg
                }
              />
            </Grid>
            
            {/*Don't remove commented for temporary fix*/}
            {/* <Grid
              item
              xs={12}
              style={{
                position: "relative",
                margin: "8px 0",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  height: 130,
                  border: "1px dashed #9CA3AF",
                  borderRadius: "8px",
                }}
              >
                <div style={{ width: "24px", height: "24px" }}>
                  <img src={Images.upload_icon} alt="upload-icon"></img>
                </div>

                <InputLabel>
                  <b>
                    <span
                      style={{
                        cursor: "pointer",
                        color: "#2563EB",
                      }}
                    >
                      Click to upload Images
                    </span>{" "}
                    or drag and drop{" "}
                  </b>
                </InputLabel>
                <InputLabel style={{ fontSize: 12, color: "#6B7280" }}>
                  {"PNG, JPG, (max size 1200*800)"}
                </InputLabel>
              </div>
              <input
                type="file"
                accept="image/jpeg, image/png, image/jpg"
                multiple
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  opacity: 0,
                  cursor: "pointer",
                  width: "100%",
                  height: "130px",
                }}
                onChange={(e) => {
                  const chosenFiles = Array.prototype.slice.call(
                    e.target.files
                  );
                  let showMsg = false;
                  let limit = false;
                  const newArr = [...budgetDetails?.photo_origin];
                  const rejected = chosenFiles.every(
                    (item) =>
                      item.type === "image/png" ||
                      item.type === "image/jpg" ||
                      item.type === "image/jpeg"
                  );
                  if (!rejected) {
                    toast.error("You can only add jpeg,jpg or png");
                  }
                  const filteredFiles = chosenFiles.filter(
                    (item) =>
                      item.type === "image/png" ||
                      item.type === "image/jpg" ||
                      item.type === "image/jpeg"
                  );
                  filteredFiles.map((item) => {
                    const bool = checkImgSize(item);
                    if (bool && newArr.length < 5) {
                      newArr.push(item);
                    } else if (newArr.length >= 4) {
                      limit = true;
                    } else {
                      showMsg = true;
                    }
                  });
                  if (limit) {
                    toast.error("You can upload maximum 5 files");
                  } else if (showMsg) {
                    toast.error(
                      "Some registraion you are attempting to upload exceeds the maximum file size limit of 3 MB. Please reduce the size of your image and try again."
                    );
                  }
                  let shouldUpload =
                    isArray(newArr) &&
                    !isEmpty(newArr) &&
                    newArr?.filter((elem) => typeof elem !== "string");
                  if (shouldUpload) {
                    UploadFileDirectly(shouldUpload);
                  }
                }}
                ref={fileInputRef}
              />
              <FormHelperText
                error={errObj.photoErr}
                style={{ fontFamily: "Poppins-Regular" }}
              >
                {errObj.photoMsg}
              </FormHelperText>
              <Grid
                item
                style={{
                  marginTop: budgetDetails?.photo_origin?.length > 0 && 40,
                  overflowY: "auto",
                  maxHeight: "200px",
                  width: "100%",
                }}
              >
                {displayImagesView()}
              </Grid>
            </Grid> */}

            <div
              style={{
                marginTop: 12,
                marginBottom: 12,
                height: 1,
                width: "100%",
                background: "#EEF0F3",
              }}
            />

            <Grid
              item
              container
              justifyContent={"center"}
              gap={sm ? 1 : 2}
              wrap="nowrap"
            >
              <Grid item xs={6}>
                <div className="cancel" onClick={handleCloseCreation}>
                  Cancel
                </div>
              </Grid>
              <Grid item xs={6}>
                {mode === "modal" ? (
                  <Button
                    fullWidth
                    variant="contained"
                    onClick={() => validate(false)}
                  >
                    Update Budget
                  </Button>
                ) : (
                  <Button
                    fullWidth
                    variant="contained"
                    onClick={() => validate(false)}
                  >
                    Create Budget
                  </Button>
                )}
              </Grid>
            </Grid>

          </Box>
        </Fade>
      </Modal>
    );
  }

  const validate = (isUpdateModalVisible) => {
    const error = { ...errObj };
    let valid = true;

    if (isEmpty(budgetDetails.name?.trim())) {
      valid = false;
      error.bNameErr = true;
      error.bNameMsg = "Please enter the name";
    } else if (budgetDetails?.name?.length > 50) {
      valid = false;
      error.bNameErr = true;
      error.bNameMsg = "Please enter the name less then 50 characters";
    }

    const positiveIntRegex = /^[1-9]\d*$/;
    const positiveFloatRegex = /^[0-9]*\.?[0-9]{0,2}$/;
    const regex = /^\d+(\.\d+)?$/;

    if (
      isEmpty(budgetDetails?.material_type?.trim()) &&
      (isEmpty(budgetDetails?.material_unit) ||
        isNull(budgetDetails?.material_unit)) &&
      isEmpty(budgetDetails.material_unit_price?.toString()) &&
      isEmpty(budgetDetails?.qty?.toString()) &&
      isEmpty(budgetDetails?.manpower_rate?.toString()) &&
      isEmpty(budgetDetails?.days?.toString())
    ) {
      if (isEmpty(budgetDetails.material_type?.trim())) {
        valid = false;
        error.materialTypeErr = true;
        error.materialTypeMsg = "Please enter the material type";
      }

      if (isEmpty(budgetDetails.material_unit_price?.toString())) {
        valid = false;
        error.materialUnitPriceErr = true;
        error.materialUnitPriceMsg = "Please enter the material unit price";
      } else if (
        !positiveFloatRegex.test(budgetDetails.material_unit_price) ||
        parseFloat(budgetDetails.material_unit_price) <= 0
      ) {
        valid = false;
        error.materialUnitPriceErr = true;
        error.materialUnitPriceMsg = "Please enter valid material unit price";
      }
      if (isEmpty(budgetDetails?.qty?.toString())) {
        valid = false;
        error.quantityErr = true;
        error.quantityMsg = "Please enter the material qty";
      }else if (parseFloat(budgetDetails?.qty) <= 0) {
        valid = false;
        error.quantityErr = true;
        error.quantityMsg = "Quantity cannot be 0 or 0.00";
      } else if (!positiveFloatRegex.test(budgetDetails?.qty)) {
        valid = false;
        error.quantityErr = true;
        error.quantityMsg = "Please enter valid material quantity";
      } else if (parseFloat(budgetDetails?.qty) >= 100000) {
        valid = false;
        error.quantityErr = true;
        error.quantityMsg = "Please enter Quantity less then 100000";
      }

      if (
        isEmpty(budgetDetails.material_unit) ||
        isNull(budgetDetails?.material_unit)
      ) {
        valid = false;
        error.unitErr = true;
        error.unitMsg = "Please enter material unit";
      }

      if (isEmpty(budgetDetails?.manpower_rate?.toString())) {
        valid = false;
        error.manpowerRateErr = true;
        error.manpowerRateMsg = "Please enter the manpower rate";
      } else if (!regex.test(budgetDetails?.manpower_rate)) {
        valid = false;
        error.manpowerRateErr = true;
        error.manpowerRateMsg = "Please enter valid manpower rate";
      } else if (budgetDetails?.manpower_rate >= 100000) {
        valid = false;
        error.manpowerRateErr = true;
        error.manpowerRateMsg = "Please enter valid manpower rate under 10000 ";
      }

      if (isEmpty(budgetDetails?.days?.toString())) {
        valid = false;
        error.daysErr = true;
        error.daysMsg = "Please enter the days";
      } else if (!positiveIntRegex.test(budgetDetails?.days)) {
        valid = false;
        error.daysErr = true;
        error.daysErr = "Please enter valid days";
      } else if (budgetDetails?.days >= 365) {
        valid = false;
        error.daysErr = true;
        error.daysMsg = "Please enter days under 365";
      }
    } else {
      if (
        !isEmpty(budgetDetails.material_type?.trim()) ||
        !isEmpty(budgetDetails.material_unit_price?.toString()) ||
        (!isEmpty(budgetDetails.material_unit) &&
          !isNull(budgetDetails?.material_unit)) ||
        !isEmpty(budgetDetails?.qty?.toString())
      ) {
        if (isEmpty(budgetDetails.material_type?.trim())) {
          valid = false;
          error.materialTypeErr = true;
          error.materialTypeMsg = "Please enter the material type";
        }
        if (!budgetDetails.material_unit_price) {
          valid = false;
          error.materialUnitPriceErr = true;
          error.materialUnitPriceMsg = "Please enter the material unit price";
        } else if (
          !positiveFloatRegex.test(budgetDetails.material_unit_price) ||
          parseFloat(budgetDetails.material_unit_price) <= 0
        ) {
          valid = false;
          error.materialUnitPriceErr = true;
          error.materialUnitPriceMsg = "Please enter valid material unit price";
        }

        if (isEmpty(budgetDetails?.qty?.toString())) {
          valid = false;
          error.quantityErr = true;
          error.quantityMsg = "Please enter the material qty";
        } else if (parseFloat(budgetDetails?.qty) <= 0) {
          valid = false;
          error.quantityErr = true;
          error.quantityMsg = "Quantity cannot be 0 or 0.00";
        } else if (!positiveFloatRegex.test(budgetDetails?.qty)) {
          valid = false;
          error.quantityErr = true;
          error.quantityMsg = "Please enter a valid material qty";
        } else if (parseFloat(budgetDetails?.qty) >= 100000) {
          valid = false;
          error.quantityErr = true;
          error.quantityMsg = "Please enter Quantity less than 100000";
        }

        if (isEmpty(budgetDetails.material_unit)) {
          valid = false;
          error.unitErr = true;
          error.unitMsg = "Please enter material unit";
        }
      }
      if (
        !isEmpty(budgetDetails?.manpower_rate?.toString()) ||
        !isEmpty(budgetDetails?.days?.toString())
      ) {
        if (isEmpty(budgetDetails?.manpower_rate?.toString())) {
          valid = false;
          error.manpowerRateErr = true;
          error.manpowerRateMsg = "Please enter the manpower rate";
        } else if (!regex.test(budgetDetails.manpower_rate)) {
          valid = false;
          error.manpowerRateErr = true;
          error.manpowerRateMsg = "Please enter valid manpower rate";
        } else if (budgetDetails?.manpower_rate >= 100000) {
          valid = false;
          error.manpowerRateErr = true;
          error.manpowerRateMsg =
            "Please enter valid manpower rate under 10000 ";
        }

        if (
          isEmpty(budgetDetails?.days?.toString()) ||
          budgetDetails?.days <= 0
        ) {
          valid = false;
          error.daysErr = true;
          error.daysMsg = "Please enter the days";
        } else if (!positiveIntRegex.test(budgetDetails?.days)) {
          valid = false;
          error.daysErr = true;
          error.daysMsg = "Please enter valid days";
        }
      }
    }
    setErrObj(error);
    console.log(
      "budgetDetails",budgetDetails
    )
    const newBudgets = { ...proposalDetails.budgets };
    const amount =
      parseInt(budgetDetails.days || 0) *
        parseInt(budgetDetails.manpower_rate || 0) +
      parseFloat(budgetDetails.qty || 0) *
        parseFloat(budgetDetails.material_unit_price || 0);
    if (valid) {
      handleCloseCreation();
      if (
        _.isObject(selectedBudget?.data) &&
        !_.isEmpty(selectedBudget?.data)
      ) {
        // const amount =
        //   parseInt(budgetDetails.days || 0) *
        //     parseInt(budgetDetails.manpower_rate || 0) +
        //   parseInt(budgetDetails.qty || 0) *
        //     parseInt(budgetDetails.material_unit_price || 0);

        selectedBudget.data = budgetDetails;
        newBudgets[selectedBudget.id] = {
          ...selectedBudget.data,
          amount,
        };
        // setBudgets((prev) => ({
        //   ...prev,
        //   [selectedBudget.id]: { ...selectedBudget.data, amount },
        // }));
      } else {
        const id = uuidv4();
        // const amount =
        //   parseInt(budgetDetails.days || 0) *
        //   parseInt(budgetDetails.manpower_rate || 0) +
        //   parseInt(budgetDetails.qty || 0) *
        //   parseInt(budgetDetails.material_unit_price || 0);
        // dispatch(setProposalDetails({
        //   ...proposalDetails,
        //   budgets: {
        //     ...proposalDetails.budgets,
        //     [id]: {
        //       ...proposalDetails.budgets[id],
        //       ...budgetDetails,
        //       amount,
        //     }
        //   }
        // }));

        newBudgets[id] = {
          ...proposalDetails.budgets[id],
          ...budgetDetails,
          amount,
        };

        // dispatch(setProposalDetails({
        //   ...proposalDetails,
        //   budgets: {
        //     ...proposalDetails.budgets,
        //     [id]: {
        //       ...proposalDetails.budgets[id],
        //       ...budgetDetails,
        //       amount,
        //     }
        //   }
        // }));
        dispatch(
          setErrors({
            ...errors,
            isPaymentGroupsValid: false,
          })
        );
        // setBudgets((prev) => ({
        //   ...prev,
        //   [id]: {
        //     ...prev[id],
        //     ...budgetDetails,
        //     amount,
        //   },
        // }));
      }

      dispatch(
        setProposalDetails({
          ...proposalDetails,
          budgets: { ...newBudgets },
        })
      );
    } else {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }

    setSelectedBudget({});
  };

  const handleRowClick = (event, budget, id) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setSelectedBudget({
      data: budget,
      id,
    });
  };

  function renderMilestonesWithBudgets() {
    return Object.keys(proposalDetails.milestones).map((key) => {
      const milestone = proposalDetails.milestones[key];
      return (
        <SingleMilestoneAccordion milestone={milestone} key={key}>
          <Stack width="100%">
            {Object.keys(proposalDetails.budgets).map((budgetKey) => {
              const budget = proposalDetails.budgets[budgetKey];
              if (key == budget.milestone_id) {
                return (
                  <Stack padding="16px">
                    <SingleBudgetAccordion
                      budget={budget}
                      index={budgetKey}
                      handleRowClick={handleRowClick}
                      isUpdateDraftProposal={isUpdateDraftProposal}
                      onDelete={handleDelete}
                      setBudget={setBudgetDetails}
                    />
                  </Stack>
                );
              }
              return null;
            })}
          </Stack>
          <Grid item container alignItems={"center"} padding="16px 32px">
            <Button
              variant="contained"
              onClick={(e) => {
                setBudgetDetails({
                  ...budgetDetails,
                  milestone_id: key,
                });
                setIsCreationOpen(true);
              }}
            >
              <AddCircleOutlineOutlinedIcon style={{ marginRight: 4 }} />
              Add Budget
            </Button>
          </Grid>
        </SingleMilestoneAccordion>
      );
    });
  }

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedBudget(null);
  };

  const handleEdit = () => {
    setAnchorEl(null);
    setVisible(true);
    setBudgetDetails(selectedBudget.data);
  };

  // const handlePreviousStep = () => {
  // const budgetIds = [];
  // for (let key in proposalDetails.budgets) {
  //   budgetIds.push(key);
  // }
  // dispatch(
  //   setProposalDetails({
  //     ...proposalDetails,
  //     milestones,
  //     budgets,
  //     budgetIds,
  //   })
  // );
  // handleTabValue(1);
  // };

  const handleDelete = () => {
    setAnchorEl(null);
    setDeleteModal(true);
    setBudgetDetails(selectedBudget.data);
  };

  const handleDeleteBudget = () => {
    const newBudgets = {};
    for (let key in proposalDetails.budgets) {
      if (key !== selectedBudget.id) {
        newBudgets[key] = proposalDetails.budgets[key];
      }
    }
    dispatch(
      setProposalDetails({
        ...proposalDetails,
        budgets: { ...newBudgets },
      })
    );

    // setBudgets(newBudgets);
    setSelectedBudget({});
    setDeleteModal(false);
  };

  // const handleSubmit = () => {
  // if (Object.keys(budgets).length < 1)
  //   return toast.error("Please add atleast one budget");
  // const budgetIds = [];
  // for (let key in budgets) {
  //   budgetIds.push(key);
  // }

  // dispatch(
  //   setProposalDetails({
  //     ...proposalDetails,
  //     milestones,
  //     budgets,
  //     budgetIds,
  //   })
  // );
  // handleDisableTab("paymentGrouptab");
  // handleTabValue(3);
  // };

  // const convertBase64ToImageFile = (base64String, filename) => {
  //   const arr = base64String?.split(",");
  //   const mimeType = arr[0].match(/:(.*?);/)[1];
  //   const bstr = atob(arr[1]);
  //   let n = bstr.length;
  //   const uint8Array = new Uint8Array(n);
  //
  //   while (n--) {
  //     uint8Array[n] = bstr.charCodeAt(n);
  //   }
  //   const file = new File([uint8Array], filename, { type: mimeType });
  //
  //   return file;
  // };

  // const convertPhotoOriginToFiles = (budget) => {
  //   const photoOriginFiles = budget?.photo_origin?.map(
  //     (base64String, index) => {
  //       const filename = `photo_origin_${index + 1}.jpg`;
  //
  //       return convertBase64ToImageFile(base64String, filename);
  //     }
  //   );
  //   return photoOriginFiles;
  // };

  // const convertProjectToFiles = () => {
  //   const projectFiles = proposalDetails?.project?.map(
  //     (base64String, index) => {
  //       const filename = `project_image_${index + 1}.jpg`;
  //       return convertBase64ToImageFile(base64String, filename);
  //     }
  //   );
  //
  //   return projectFiles;
  // };

  // const proposalId = isUpdateDraftProposal
  //   ? villa?.proposal_id
  //   : proposalDetails.saveDraftProposalId
  //   ? proposalDetails.saveDraftProposalId
  //   : null;
  // const query = isUpdateDraftProposal ? `?proposal_id=${proposalId}` : "";
  // const [isConfirmSaveDraft, setIsConfirmSaveDraft] = useState(false);

  // const handleSaveDraft = async () => {
  // const projectFiles = convertProjectToFiles();
  // const payload = getSaveDraftPayload({
  //   proposalDetails,
  // projectFiles,
  // budgets,
  // });
  // take this to transform data
  // Object.values(proposalDetails.budgets).forEach((budget, index) => {
  //   const photoOriginFiles = convertPhotoOriginToFiles(budget);
  //   payload[`budget_image_${index + 1}`] = photoOriginFiles;
  // });
  // try {
  //   const response = await getAPIProgressData(
  //     `${Setting.endpoints.createDraftProposal}${query}`,
  //     "POST",
  //     payload,
  //     true
  //   );

  // if (response.success) {
  //   setProposalDetails({
  //     ...proposalDetails,
  //     saveDraftProposalId: proposalId,
  //   });
  //   toast.success("Draft created Successfully");
  //   navigate("/dashboard");
  // } else {
  //   toast.error(response.message);
  // }
  // } catch (error) {
  //   toast.error(error.toString());
  // }
  // };

  return (
    <>
      <Stack width="100%" gap="16px" height="90%">
        <div className={"alert"}>
          <span className="label"> Total Budget amount </span>
          <span className="cur">{formattedAmount(totalBudgetAmount)}</span>
        </div>

        {isCreationOpen && renderBudgetCreateForm("form")}
        {visible && renderBudgetCreateForm("modal")}

        <Divider width="100%" />

        <Stack width="100%" gap="16px" height="100%" overflow="auto">
          <div className="secondaryTitle">Milestones</div>
          <Divider width="100%" />
          <Grid container>
            <Stack width="100%" gap="8px">
              {renderMilestonesWithBudgets()}
            </Stack>
          </Grid>
        </Stack>
        <Divider sx={{ width: "100%" }} />
        {/*<Stack direction="row" justifyContent="space-between">*/}
        {/*  <Stack>*/}
        {/*    <Button*/}
        {/*      variant="outlined"*/}
        {/*      size="small"*/}
        {/*      sx={{ boxShadow: "none", padding: "12px 24px" }}*/}
        {/*      onClick={handlePreviousStep}*/}
        {/*    >*/}
        {/*      Previous Step*/}
        {/*    </Button>*/}
        {/*  </Stack>*/}
        {/*  <Stack direction="row" gap={2}>*/}
        {/*    <Button*/}
        {/*      variant="outlined"*/}
        {/*      size="small"*/}
        {/*      className="conBtn"*/}
        {/*      sx={{ boxShadow: "none", padding: "12px 24px" }}*/}
        {/*      onClick={() => setIsOpen(true)}*/}
        {/*    >*/}
        {/*      Cancel*/}
        {/*    </Button>*/}
        {/*    <Button*/}
        {/*      variant="contained"*/}
        {/*      size="small"*/}
        {/*      className="conBtn"*/}
        {/*      sx={{ padding: "12px 24px" }}*/}
        {/*      onClick={handleSubmit}*/}
        {/*    >*/}
        {/*      Save And Continue*/}
        {/*    </Button>*/}
        {/*    <Button*/}
        {/*      variant="contained"*/}
        {/*      size="large"*/}
        {/*      onClick={() => setIsConfirmSaveDraft(true)}*/}
        {/*    >*/}
        {/*      Save Draft*/}
        {/*    </Button>*/}
        {/*  </Stack>*/}
        {/*</Stack>*/}
      </Stack>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            ml: 8,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
          },
        }}
        transformOrigin={{
          horizontal: "right",
          vertical: "top",
        }}
        anchorOrigin={{
          horizontal: "right",
          vertical: "bottom",
        }}
      >
        <MenuItem
          style={{
            fontFamily: "Poppins-Medium",
            padding: "12px 36px 12px 12px",
          }}
          onClick={handleEdit}
        >
          Edit
        </MenuItem>
        <Divider style={{ margin: 0 }} />
        <MenuItem
          style={{
            fontFamily: "Poppins-Medium",
            padding: "12px 36px 12px 12px",
          }}
          onClick={handleDelete}
        >
          Delete
        </MenuItem>
      </Menu>
      <ConfirmModel
        visible={deleteModal}
        handleClose={() => setDeleteModal(false)}
        confirmation={handleDeleteBudget}
        message={`Are you sure you want to delete ${selectedBudget?.data?.name} budget?`}
      />
      {/*<ConfirmModel*/}
      {/*  visible={isOpen}*/}
      {/*  handleClose={() => setIsOpen(false)}*/}
      {/*  confirmation={handleConfirm}*/}
      {/*  message="Are you sure to discard the changes"*/}
      {/*/>*/}
      {/*<ConfirmModel*/}
      {/*  visible={isConfirmSaveDraft}*/}
      {/*  handleClose={() => setIsConfirmSaveDraft(false)}*/}
      {/*  confirmation={handleSaveDraft}*/}
      {/*  message="Are you sure you want to save and quit?"*/}
      {/*/>*/}
    </>
  );
};
export default Budgets;
