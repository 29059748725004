import React from "react";
import { List, ListItem, ListItemText } from "@mui/material";
import DescriptionIcon from "@mui/icons-material/Description";
import moment from "moment";

const MilestoneCard = ({ arr, getExtraMilestoneUpdates }) => {
  return (
    <List
      style={{
        display: "flex",
        flexDirection: "row",
        gap: "15px",
        overflowX: "auto",
        scrollbarWidth: "none", 
        msOverflowStyle: "none", 
      }}
      sx={{
        "&::-webkit-scrollbar": {
          display: "none",
        },
      }}
    >
      {arr?.map((item) => (
        <ListItem
          key={item?.image?.id}
          sx={{
            mb: 1,
            border: "1px solid #ddd",
            borderRadius: 1,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: "10px",
            width: "250px",
          }}
        >
          {item?.image?.file_extention === "application/pdf" ? (
            <DescriptionIcon
              sx={{
                padding: 1,
                width: "230px",
                height: "150px",
                cursor: "pointer",
              }}
              onClick={() =>
                getExtraMilestoneUpdates(item?.image?.assign_id, item?.date)
              }
            />
          ) : (
            <img
              src={item?.image?.path}
              alt={
                item?.image?.file_extention === "application/pdf"
                  ? "File"
                  : "Image"
              }
              style={{
                height: "150px",
                width: "230px",
                alignItems: "center",
                display: "flex",
                cursor: "pointer",
              }}
              onClick={() =>
                getExtraMilestoneUpdates(item?.image?.assign_id, item?.date)
              }
            />
          )}
          <ListItemText
            primary={
              <div
                style={{
                  textDecoration: "none",
                  color: "inherit",
                  fontSize: "15px",
                  textAlign: "center",
                  display: "flex",
                  flexDirection: "column",
                  gap: "5px",
                }}
              >
                <p>{moment(item?.date).format("MMMM DD, YYYY")}</p>
                <p
                  style={{
                    backgroundColor: "#F0F8FF",
                    color: "#002D62",
                    padding: ".3vh .2vw",
                    borderRadius: "4px",
                    fontSize: "13px",
                    fontWeight: "800",
                  }}
                >
                  {item?.no_of_updates}&nbsp;
                  {item?.no_of_updates <= 1 ? "Update" : "Updates"}
                </p>
              </div>
            }
            sx={{ flexGrow: 1 }}
          />
        </ListItem>
      ))}
    </List>
  );
};

export default MilestoneCard;
