import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { toast } from "react-toastify";
import { Stack, Button, Divider, Typography } from "@mui/material";
import authActions from "../../../redux/reducers/auth/actions";

import useStyles from "./styles";
// import { getAPIProgressData } from "../../../utils/APIHelper";
// import { Setting } from "../../../utils/Setting";
// import ConfirmModel from "../../../components/ConfirmModel";
// import ProfileSuccessModal from "../../../components/ProfileSuccessModal";
import SingleMilestoneAccordion from "../../../components/SingleMilestoneAccordian";
import SingleBudgetAccordion from "../../../components/SingleBudgetAccordian";
import { useLocation, useNavigate } from "react-router-dom";
import MilestonePaymentGroup from "../PaymentGroup/MilestonePayementGroup";
import { transformDateFormat } from "../../../utils/CommonFunction";
import HomeOwnerSummary from "../../RequestedProposal/HomeOwnerSummary";
// import { getCreateProposalPayload, getSaveDraftPayload } from "../helpers";

const Details = ({ handleTabValue }) => {
  // const location = useLocation();
  // const villa = location?.state?.villa ? location?.state?.villa : {};
  // const isCreateProposal = location?.state?.isCreateProposal || false;
  // const isUpdateDraftProposal = Boolean(location?.state?.isUpdateDraftProposal);
  const { proposalDetails } = useSelector((state) => state.auth);
  const {
    budgets,
    start_date,
    end_date,
    description,
    milestones,
    name,
    project_type,
    scope_of_work,
    email,
    form_json,
    dimensions,
  } = proposalDetails;
  const classes = useStyles();
  // const dispatch = useDispatch();
  // const navigate = useNavigate();
  // const { setProposalDetails } = authActions;
  // const [buttonLoader, setButtonLoader] = useState(false);
  // const [visibleFinal, setVisibleFinal] = useState(false);
  // const [proposalModal, setProposalModal] = useState(false);
  // const [isOpen, setIsOpen] = useState(false);
  // const [isConfirmSaveDraft, setIsConfirmSaveDraft] = useState(false);

  // async function createproposalApicall(data) {
  //   setButtonLoader(true);
  //   try {
  //     const response = await getAPIProgressData(
  //       Setting.endpoints.directproposal,
  //       "POST",
  //       data,
  //       true
  //     );
  //
  //     if (response.success) {
  //       setVisibleFinal(false);
  //       setProposalModal(true);
  //     } else {
  //       toast.error(response.message);
  //     }
  //     setButtonLoader("");
  //   } catch (error) {
  //     toast.error(error);
  //     setButtonLoader("");
  //   }
  // }

  // const convertBase64ToImageFile = (base64String, filename) => {
  //   const arr = base64String?.split(",");
  //   const mimeType = arr[0].match(/:(.*?);/)[1];
  //   const bstr = atob(arr[1]);
  //   let n = bstr.length;
  //   const uint8Array = new Uint8Array(n);
  //
  //   while (n--) {
  //     uint8Array[n] = bstr.charCodeAt(n);
  //   }
  //   const file = new File([uint8Array], filename, { type: mimeType });
  //
  //   return file;
  // };

  // const convertPhotoOriginToFiles = (budget) => {
  //   const photoOriginFiles = budget?.photo_origin?.map(
  //     (base64String, index) => {
  //       const filename = `photo_origin_${index + 1}.jpg`;
  //
  //       return convertBase64ToImageFile(base64String, filename);
  //     }
  //   );
  //   return photoOriginFiles;
  // };

  // const convertProjectToFiles = () => {
  //   const projectFiles = proposalDetails?.project?.map(
  //     (base64String, index) => {
  //       const filename = `project_image_${index + 1}.jpg`;
  //       return convertBase64ToImageFile(base64String, filename);
  //     }
  //   );
  //
  //   return projectFiles;
  // };

  // const handlePreviousStep = () => {
  //   handleTabValue(3);
  // };

  // const proposalId = isUpdateDraftProposal
  //   ? villa?.proposal_id
  //   : proposalDetails.saveDraftProposalId
  //   ? proposalDetails.saveDraftProposalId
  //   : null;
  // const query = isUpdateDraftProposal ? `?proposal_id=${proposalId}` : "";

  // const handleSaveDraft = async () => {
  //   const projectFiles = convertProjectToFiles();
  //   const payload = getSaveDraftPayload({ proposalDetails, projectFiles });
  //   Object.values(budgets).forEach((budget, index) => {
  //     const photoOriginFiles = convertPhotoOriginToFiles(budget);
  //     payload[`budget_image_${index + 1}`] = photoOriginFiles;
  //   });
  //   try {
  //     const response = await getAPIProgressData(
  //       `${Setting.endpoints.createDraftProposal}${query}`,
  //       "POST",
  //       payload,
  //       true
  //     );
  //
  //     if (response.success) {
  //       setProposalDetails({
  //         ...proposalDetails,
  //         saveDraftProposalId: proposalId,
  //       });
  //       toast.success("Draft created Successfully");
  //       navigate("/dashboard");
  //     } else {
  //       toast.error(response.message);
  //     }
  //   } catch (error) {
  //     toast.error(error.toString());
  //   }
  // };

  // const handleSubmit = () => {
  //   const projectFiles = convertProjectToFiles();
  //   const payload = getCreateProposalPayload(proposalDetails, projectFiles);
  //   Object.values(budgets).forEach((budget, index) => {
  //     const photoOriginFiles = convertPhotoOriginToFiles(budget);
  //     payload[`budget_image_${index + 1}`] = photoOriginFiles;
  //   });
  //   if (isUpdateDraftProposal) {
  //     payload["proposal_id"] = proposalId;
  //   }
  //   createproposalApicall(payload);
  // };

  // const handleConfirm = () => {
  //   setIsOpen(false);
  //   dispatch(setProposalDetails({}));
  //   navigate("/dashboard");
  // };

  return (
    <Stack width="100%" gap="16px">
      <Stack width="100%" gap="16px">
        <Typography
          className={classes.projectInformation}
          sx={{ backgroundColor: "#F3F4F9" }}
        >
          Project Details
        </Typography>
        <Stack gap="8px" width="100%">
          <Stack
            direction="row"
            gap="8px"
            padding="0 12px"
            width="100%"
            justifyContent="space-between"
            flexWrap="wrap"
          >
            <Stack>
              <Typography className={classes.informationCard}>
                Project Name
              </Typography>
              <Typography className={classes.value}>{name}</Typography>
            </Stack>
            <Stack>
              <Typography className={classes.informationCard}>
                Project Type
              </Typography>
              <Typography className={classes.value}>{project_type}</Typography>
            </Stack>

            <Stack>
              <Typography className={classes.informationCard}>Email</Typography>
              <Typography className={classes.value}>{email || "NA"}</Typography>
            </Stack>
            {start_date ? (
              <Stack>
                <Typography className={classes.informationCard}>
                  Project Dates
                </Typography>
                <Typography className={classes.value}>
                  {start_date}-{end_date}
                </Typography>
              </Stack>
            ) : (
              <Stack></Stack>
            )}
          </Stack>
          <Divider />
          {proposalDetails?.scope_of_work && (
            <Stack padding="0 12px">
              <Stack>
                <Typography className={classes.informationCard}>
                  Scope of work
                </Typography>
                <Typography className={classes.value}>
                  {scope_of_work}
                </Typography>
              </Stack>
            </Stack>
          )}
          {proposalDetails?.scope_of_work && <Divider />}
          {proposalDetails?.description && (
            <Stack padding="0 12px">
              <Stack>
                <Typography className={classes.informationCard}>
                  Project Description
                </Typography>
                <Typography className={classes.value}>{description}</Typography>
              </Stack>
            </Stack>
          )}
        </Stack>
        {proposalDetails?.description && <Divider />}
      </Stack>
      <Stack>
        <HomeOwnerSummary proposalDetails={proposalDetails} classes={classes} />
      </Stack>
      <Stack gap="16px" width="100%">
        <Stack direction="row" gap="4px" width="100%">
          {Object.keys(proposalDetails?.milestones).length > 0 && (
            <Typography
              className={classes.projectInformation}
              padding="4px 16px"
            >
              Milestones
              <span
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "24px",
                  height: "24px",
                  backgroundColor: "#E9B55C",
                  fontWeight: "bold",
                  borderRadius: 22,
                  fontSize: 12,
                }}
              >
                {Object.keys(proposalDetails.milestones).length}
              </span>
            </Typography>
          )}
        </Stack>
        <Stack width="100%" gap="8px">
          {Object.keys(milestones).map((key) => {
            const milestone = milestones[key];
            return (
              <SingleMilestoneAccordion milestone={milestone} key={key}>
                <Stack width="100%">
                  {Object.keys(budgets).map((budgetKey) => {
                    const budget = budgets[budgetKey];

                    if (key == budget.milestone_id) {
                      return (
                        <Stack padding="16px">
                          <SingleBudgetAccordion
                            budget={budget}
                            index={budgetKey}
                          />
                        </Stack>
                      );
                    }
                    return null;
                  })}
                </Stack>
              </SingleMilestoneAccordion>
            );
          })}
        </Stack>
      </Stack>

      {Object.keys(proposalDetails.milestones).length > 0 && <Divider />}
      <Stack gap="16px" width="100%">
        {Object.keys(proposalDetails?.payment_group).length > 0 && (
          <Typography className={classes.projectInformation} padding="4px 16px">
            Payment Groups
            <span
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "24px",
                height: "24px",
                backgroundColor: "#E9B55C",
                fontWeight: "bold",
                borderRadius: 22,
                fontSize: 12,
              }}
            >
              {proposalDetails?.payment_group &&
                Object.keys(proposalDetails.payment_group).length}
            </span>
          </Typography>
        )}

        {proposalDetails?.payment_group &&
          Object.keys(proposalDetails.payment_group).map((key) => {
            const pGroup = proposalDetails.payment_group[key];
            return (
              <MilestonePaymentGroup
                group={pGroup.group}
                groupName={pGroup.groupName}
                dueDate={pGroup.dueDate}
                groupId={key}
                isExpandable
              />
            );
          })}
      </Stack>
      {/*<Stack direction="row" justifyContent="space-between">*/}
      {/*  <Stack>*/}
      {/*    <Button variant="outlined" size="large" onClick={handlePreviousStep}>*/}
      {/*      Previous Step*/}
      {/*    </Button>*/}
      {/*  </Stack>*/}
      {/*  <Stack direction="row" justifyContent="flex-end" gap="16px">*/}
      {/*    <Button*/}
      {/*      variant="outlined"*/}
      {/*      size="large"*/}
      {/*      onClick={() => setIsOpen(true)}*/}
      {/*    >*/}
      {/*      Cancel*/}
      {/*    </Button>*/}
      {/*    <Button*/}
      {/*      variant="contained"*/}
      {/*      size="large"*/}
      {/*      onClick={() => setVisibleFinal(true)}*/}
      {/*    >*/}
      {/*      Submit*/}
      {/*    </Button>*/}
      {/*    <Button*/}
      {/*      variant="contained"*/}
      {/*      size="large"*/}
      {/*      onClick={() => setIsConfirmSaveDraft(true)}*/}
      {/*    >*/}
      {/*      Save Draft*/}
      {/*    </Button>*/}
      {/*  </Stack>*/}
      {/*</Stack>*/}

      {/*<ConfirmModel*/}
      {/*  visible={visibleFinal}*/}
      {/*  loader={buttonLoader}*/}
      {/*  title={"Submit"}*/}
      {/*  handleClose={() => setVisibleFinal(false)}*/}
      {/*  confirmation={handleSubmit}*/}
      {/*  message={`Are you sure you want to submit proposal?`}*/}
      {/*/>*/}

      {/*<ConfirmModel*/}
      {/*  visible={isOpen}*/}
      {/*  handleClose={() => setIsOpen(false)}*/}
      {/*  confirmation={handleConfirm}*/}
      {/*  message="Are you sure to discard the changes"*/}
      {/*/>*/}
      {/*<ConfirmModel*/}
      {/*  visible={isConfirmSaveDraft}*/}
      {/*  handleClose={() => setIsConfirmSaveDraft(false)}*/}
      {/*  confirmation={handleSaveDraft}*/}
      {/*  message="Are you sure you want to save and quit"*/}
      {/*/>*/}

      {/*{proposalModal && (*/}
      {/*  <ProfileSuccessModal*/}
      {/*    title="Congrats!"*/}
      {/*    msg="Proposal successfully submitted!"*/}
      {/*    btnTitle="Continue"*/}
      {/*    visible={proposalModal}*/}
      {/*  />*/}
      {/*)}*/}
    </Stack>
  );
};
export default Details;
