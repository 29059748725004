import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Divider,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import useStyles from "../styles";
import { useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SingleMilestoneAccordion from "../../../../components/SingleMilestoneAccordian";
import Images from "../../../../config/images";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import theme from "../../../../config/theme";
import {
  formattedAmount,
  transformDateFormat,
} from "../../../../utils/CommonFunction";

const MilestonePaymentGroup = ({
  groupName,
  dueDate,
  group,
  amount,
  handleEditPaymentGroup,
  handleDelete,
  handleInspectButton,
  isRequestedProposal,
  isOngoingProject,
  isExpandable,
  status,
}) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(true);
  const handleChangeExpanded = () => () => {
    setExpanded((prev) => !prev);
  };
  // Function to determine the source of milestones
  const getMilestones = () => group?.milestones || group;
  const milestones = getMilestones();
  const md = useMediaQuery(theme.breakpoints.down("md"));
  const lg = useMediaQuery(theme.breakpoints.down("lg"));

  return (
    <Stack className={classes.groupContainer}>
      <Accordion
        style={{
          boxShadow: "none",
          borderRadius: "none",
        }}
        disableGutters
        onChange={handleChangeExpanded()}
      >
        <AccordionSummary
          aria-controls="panel1a-content"
          id="panel1a-header"
          sx={{
            ".MuiAccordionSummary-content": {
              margin: 0,
            },
            "Mui-expanded": {
              margin: 0,
            },
          }}
        >
          <Stack direction="row" width="100%" justifyContent="space-between">
            <Stack direction="row" alignItems="center" gap="16px">
              <Typography className={classes.value}>{groupName}</Typography>
              {isExpandable && (
                <span
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "24px",
                    height: "24px",
                    backgroundColor: "#E9B55C",
                    fontWeight: "bold",
                    borderRadius: 22,
                    fontSize: 12,
                  }}
                >
                  {Object.keys(milestones).length}
                </span>
              )}
              {isExpandable && (isOngoingProject === "Ongoing" || isOngoingProject === "ongoing") && (
                <Button
                  onClick={handleInspectButton}
                  variant="contained"
                  color="primary"
                >
                  Request Inspection
                </Button>
              )}
            </Stack>
            <Stack
              direction="row"
              gap={2}
              alignItems="center"
              justifyContent="flex-end"
            >
              {dueDate ? (
                <Stack marginRight={status ? "40px" : "0px"}>
                  <Typography className={classes.label}>Due Date</Typography>
                  <Typography className={classes.value}>
                    {transformDateFormat(dueDate)}
                  </Typography>
                </Stack>
              ) : (
                <Stack></Stack>
              )}
              {isRequestedProposal && amount > 0 ? (
                <Stack marginRight={!isExpandable ? "40px" : "0px"} flex={1}>
                  <Typography className={classes.label} textAlign="end">
                    Total Amount
                  </Typography>
                  <Typography className={classes.value} textAlign="end">
                    {formattedAmount(amount)}
                  </Typography>
                </Stack>
              ) : (
                <Stack flex={1}></Stack>
              )}
              {isExpandable && (
                <Stack>
                  {expanded ? (
                    <ExpandMoreIcon
                      style={{
                        display: !isExpandable ? "none" : "block",
                      }}
                    />
                  ) : (
                    <ExpandMoreIcon
                      sx={{
                        transform: "rotate(180deg)",
                        display: !isExpandable ? "none" : "block",
                      }}
                    />
                  )}
                </Stack>
              )}

              {handleEditPaymentGroup && (
                <img
                  src={Images.edit}
                  alt="edit-button"
                  onClick={handleEditPaymentGroup}
                />
              )}
              {handleDelete && (
                <DeleteOutlinedIcon
                  onClick={handleDelete}
                  sx={{ ":hover": { color: "red" } }}
                />
              )}
            </Stack>
          </Stack>
        </AccordionSummary>
        {isExpandable && (
          <AccordionDetails
            sx={{
              display: "flex",
              flexDirection: "column",
              padding: "0px",
              gap: "8px",
            }}
          >
            <Divider sx={{ margin: "8px 0px" }} />
            {Object.keys(milestones).map((milestoneKey) => {
              return (
                <SingleMilestoneAccordion
                  milestone={milestones[milestoneKey]}
                  key={milestoneKey}
                  id={milestoneKey}
                />
              );
            })}
          </AccordionDetails>
        )}
      </Accordion>
    </Stack>
  );
};
export default MilestonePaymentGroup;
