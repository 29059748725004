import { ExpandLess, ExpandMore } from "@mui/icons-material";
import {
  Collapse,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import useStyles from "../styles";
import { color } from "../../../../config/theme";
import { formattedAmount } from "../../../../utils/CommonFunction";

const BudgetItem = ({ data = {}, isNew = true }) => {
  const [open, setOpen] = useState();

  const classes = useStyles();

  const toggleView = () => {
    setOpen((prev) => !prev);
  };

  return (
    <div className={classes.budgetItem}>
      <Stack direction="row" className={classes.budgetSummaryContainer}>
        <div className={classes.budgetTitleContainer}>
          <div style={{ textTransform: "capitalize" }}>{data?.name}</div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: 4,
            }}
          >
            <div>Total:</div>
            <div>
              {data?.totalPrice ? `${formattedAmount(data?.totalPrice)}` : "-"}
            </div>
          </div>
        </div>
        <div className={classes.actionContainer}>
          <IconButton onClick={toggleView}>
            {open ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        </div>
      </Stack>
      <Collapse in={open}>
        <>
          <Table className={classes.leftAlign}>
            <TableBody>
              <TableRow>
                <TableCell
                  className={classes.leftAlign}
                  style={{
                    color: color.captionText,
                    fontFamily: "Poppins-Regular !important",
                  }}
                  align="center"
                >
                  Specification
                </TableCell>
              </TableRow>
              <TableRow key={"Specification"}>
                <TableCell>
                  <Typography
                    fontFamily={"Poppins-Regular"}
                    style={{ marginBottom: "1vh" }}
                    classes={classes.description}
                  >
                    {data?.specification || "-"}
                  </Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
          <Stack direction="row" className={classes.budgetContentContainer}>
            <div
              style={{
                width: "140px",
                height: "140px",
              }}
            >
              <img
                style={{ borderRadius: "14px" }}
                width="100%"
                height="100%"
                src={
                  Object.keys(data.file || {}).length === 0
                    ? "https://renohome.blob.core.windows.net/reno-cms/e56d3d53-e335-425f-990e-16e6b2bbee1b"
                    : data?.file?.fileUrl
                }
                alt="placeholder"
              />
            </div>

            <TableContainer
              style={{ padding: "0 !important", boxSizing: "border-box" }}
            >
              <Table className={classes.customtable}>
                <TableBody>
                  <TableRow>
                    <TableCell
                      style={{
                        color: color.captionText,
                        fontFamily: "Poppins-Regular !important",
                      }}
                      align="center"
                    >
                      Manpower rate
                    </TableCell>

                    <TableCell
                      style={{
                        color: color.captionText,
                        fontFamily: "Poppins-Regular !important",
                      }}
                      align="center"
                    >
                      Days
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        color: color.captionText,
                        fontFamily: "Poppins-Regular !important",
                      }}
                    ></TableCell>
                  </TableRow>
                  <TableRow key={"Manpower"}>
                    <TableCell align="center">
                      <Typography fontFamily={"Poppins-Regular"}>
                        {data?.manpowerRate || "-"}
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography fontFamily={"Poppins-Regular"}>
                        {data?.days || "-"}
                      </Typography>
                    </TableCell>
                    <TableCell align="center"></TableCell>
                    <TableCell align="center"></TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              <Table className={classes.customtable}>
                <TableBody>
                  <TableRow>
                    <TableCell
                      align="center"
                      style={{
                        color: color.captionText,
                        fontFamily: "Poppins-Regular !important",
                      }}
                    >
                      Material Type
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        color: color.captionText,
                        fontFamily: "Poppins-Regular !important",
                      }}
                    >
                      Material Unit
                    </TableCell>
                    <TableCell
                      style={{
                        color: color.captionText,
                        fontFamily: "Poppins-Regular !important",
                      }}
                      align="center"
                    >
                      Unit Price
                    </TableCell>
                    <TableCell
                      style={{
                        color: color.captionText,
                        fontFamily: "Poppins-Regular !important",
                      }}
                      align="center"
                    >
                      Quantity
                    </TableCell>
                  </TableRow>
                  <TableRow key={"Manpower"}>
                    <TableCell align="center">
                      <Typography fontFamily={"Poppins-Regular"}>
                        {data?.materialType || "-"}
                      </Typography>
                    </TableCell>

                    <TableCell align="center">
                      <Typography fontFamily={"Poppins-Regular"}>
                        {data?.materialUnit || "-"}
                      </Typography>
                    </TableCell>

                    <TableCell align="center">
                      <Typography fontFamily={"Poppins-Regular"}>
                        {formattedAmount(data?.materialUnitPrice) || "AED 0.00"}
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography fontFamily={"Poppins-Regular"}>
                        {data?.quantity || "-"}
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Stack>
        </>
      </Collapse>
    </div>
  );
};

export default BudgetItem;
