import {
  Box,
  Button,
  Divider,
  Fade,
  FormControl,
  Grid,
  Menu,
  MenuItem,
  Modal,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import Select from "react-select";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import theme from "../../../config/theme";
import { Close } from "@mui/icons-material";
import CInput from "../../../components/CInput";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import moment from "moment";
import authActions from "../../../redux/reducers/auth/actions";
import Images from "../../../config/images";
import _, { isEmpty, isNull } from "lodash";
import { v4 as uuidv4 } from "uuid";
import "./index.css";
import ConfirmModel from "../../../components/ConfirmModel";
import SingleMilestoneAccordion from "../../../components/SingleMilestoneAccordian";
import { transformDateFormat } from "../../../utils/CommonFunction";

const errorObj = {
  nameErr: false,
  nameMsg: "",
  descriptionErr: false,
  descriptionMsg: "",
  startErr: false,
  startMsg: "",
  endErr: false,
  endMsg: "",
  amountErr: false,
  amountMsg: "",
};

const Milestones = ({ handleTabValue, handleDisableTab, tabValue }) => {
  const sm = useMediaQuery(theme.breakpoints.down("sm"));
  const md = useMediaQuery(theme.breakpoints.down("md"));

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: sm ? 300 : 500,
    bgcolor: "background.paper",
    borderRadius: 1,
    boxShadow: 24,
    p: 4,
  };

  // const navigate = useNavigate();
  const dispatch = useDispatch();
  // const location = useLocation();
  const { proposalDetails, errors } = useSelector((state) => state.auth);
  const { setProposalDetails, setErrors } = authActions;
  // const [milestones, setMilestones] = useState({});
  const [visibleEditModal, setVisibleEditModal] = useState(false);
  const [isCreationOpen, setIsCreationOpen] = useState(false);
  // const [isOpen, setIsOpen] = useState(false);
  const [errObj, setErrObj] = useState(errorObj);
  const [anchorEl, setAnchorEl] = useState(null);
  const [visible, setVisible] = useState(false);
  const [selectedMilestone, setSelectedMilestone] = useState({});
  const [deleteModal, setDeleteModal] = useState(false);
  // const villa = location?.state?.villa ? location?.state?.villa : {};
  // const isUpdateDraftProposal = Boolean(location?.state?.isUpdateDraftProposal);
  // const [isConfirmSaveDraft, setIsConfirmSaveDraft] = useState(false);
  const milestoneTypes = [
    { label: 'Renovation', value: 'Renovation' },
    { label: 'Design', value: 'Design' }
  ];
  const [milestoneDetails, setMilestoneDetails] = useState({
    milestone_name: "",
    description: "",
    start_date: null,
    end_date: null,
    amount: null,
    in_payment_group: false,
    budgets: [],   
    tags: milestoneTypes[0]?.value,
  });

  const calculateDates = (data) => {
    const startDateArray = Object.keys(data).map((key) => {
      const milestone = data[key];
      return +new Date(milestone.start_date);
    });

    const endDateArray = Object.keys(data).map((key) => {
      const milestone = data[key];
      return +new Date(milestone.end_date);
    });

    const minStartDate = Math.min(...startDateArray);
    const maxEndDate = Math.max(...endDateArray);

    const startDate = transformDateFormat(new Date(minStartDate));
    const endDate = transformDateFormat(new Date(maxEndDate));
    return {
      startDate,
      endDate,
    };
  };

  // useEffect(() => {
  //   const findDatesError = Object.keys(proposalDetails.milestones).some(
  //     (key) => {
  //       const milestone = proposalDetails.milestones[key];
  //       return !milestone.end_date || !milestone.start_date;
  //     }
  //   );

  //   if (findDatesError) {
  //     toast.error("Please add milestones dates");
  //   }
  // }, [proposalDetails?.milestones]);

  useEffect(() => {
    const dates = calculateDates(proposalDetails?.milestones);
    if (proposalDetails.payment_group) {
      const paymentGroups = proposalDetails.payment_group;
      const newPaymentGroup = {};
      for (let key in paymentGroups) {
        const test = paymentGroups[key];
        const newGroup = {};
        let latestEndDate;
        for (let id in proposalDetails.milestones) {
          const milestone = proposalDetails.milestones[id];
          if (milestone.group_id == key) {
            newGroup[id] = milestone;
            const milestoneEndDate = new Date(milestone.end_date);
            if (!latestEndDate || milestoneEndDate > latestEndDate) {
              latestEndDate = milestoneEndDate;
            }
          }
        }

        if (Object.keys(newGroup).length) {
          newPaymentGroup[key] = {
            groupName: test.groupName,
            dueDate: latestEndDate ? latestEndDate : test.dueDate,
            group: newGroup,
          };
        }
      }
      dispatch(
        setProposalDetails({
          ...proposalDetails,
          start_date: dates.startDate,
          end_date: dates.endDate,
          payment_group: newPaymentGroup,
        })
      );
    }
  }, [proposalDetails.milestones]);
  //
  // const updatePaymentGroup = useCallback(() => {
  //   const paymentGroup = proposalDetails.payment_group;
  //   const newPaymentGroup = {};
  //   for (let key in paymentGroup) {
  //     const test = paymentGroup[key];
  //     const newGroup = {};
  //     for (let id in proposalDetails.milestones) {
  //       const milestone = proposalDetails.milestones[id];
  //       if (milestone.group_id == key) {
  //         newGroup[id] = milestone;
  //       }
  //     }
  //
  //     if (Object.keys(newGroup).length) {
  //       newPaymentGroup[key] = {
  //         groupName: test.groupName,
  //         dueDate: test.dueDate,
  //         group: newGroup,
  //       };
  //     }
  //   }
  //   return newPaymentGroup;
  // }, [proposalDetails.milestones, proposalDetails.payment_group]);

  // useEffect(() => {
  //   if (Object.keys(proposalDetails.milestones).length) {
  //     setMilestones(proposalDetails.milestones);
  //   }
  // }, [proposalDetails.milestones]);

  // const updatedBudgets = useMemo(() => {
  //   const budgets = proposalDetails.budgets;
  //   const newBudgets = {};
  //   for (let key in proposalDetails.milestones) {
  //     for (let id in budgets) {
  //       const budget = budgets[id];
  //
  //       if (budget.milestone_id == key) {
  //         newBudgets[id] = budget;
  //       }
  //     }
  //   }
  //   return newBudgets;
  // }, [proposalDetails.milestones, proposalDetails.budgets]);

  // const totalBudgetAmount = useMemo(() => {
  //   const amount = Object.keys(milestones).reduce((acc, key) => {
  //     let milestone = milestones[key];
  //     return acc + milestone.amount;
  //   }, 0);
  //   return amount;
  // }, [milestones]);

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedMilestone({});
  };

  function clearData() {
    setMilestoneDetails({
      milestone_name: "",
      description: "",
      start_date: null,
      end_date: null,
      in_payment_group: false,
      budgets: [],
      tags: milestoneTypes[0]?.value,
    });
    setErrObj(errorObj);
    handleClose();
  }

  const handleRowClick = (event, milestone, id) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setSelectedMilestone({
      data: milestone,
      id,
    });
  };

  const handleCloseCreation = () => {
    setIsCreationOpen(false);
    setVisible(false);
    setVisibleEditModal(false);
    clearData();
  };

  const validate = (isUpdateModalVisible) => {
    const error = { ...errObj };
    let valid = true;

    const stDate = new Date(milestoneDetails?.start_date);
    const enDate = new Date(milestoneDetails?.end_date);

    const st = moment(stDate, "DD/MM/YYYY").format("MMM DD, yyyy");

    if (isEmpty(milestoneDetails.milestone_name)) {
      valid = false;
      error.nameErr = true;
      error.nameMsg = "Please enter the name";
    }

    if (isEmpty(milestoneDetails.description)) {
      valid = false;
      error.descriptionErr = true;
      error.descriptionMsg = "Please enter description";
    }

    if (isNull(milestoneDetails?.start_date)) {
      valid = false;
      error.startErr = true;
      error.startMsg = "Please select the start date";
    } else if (
      (!isNull(stDate) &&
        (stDate?.toString() === "Invalid date" ||
          stDate?.toString() === "Invalid Date")) ||
      st === "Invalid date" ||
      st === "Invalid Date"
    ) {
      valid = false;
      error.startErr = true;
      error.startMsg = "Please enter valid date";
    }

    if (isNull(milestoneDetails.end_date)) {
      valid = false;
      error.endErr = true;
      error.endMsg = "Please select the end date";
    } else if (
      !isNull(enDate) &&
      (enDate?.toString() === "Invalid date" ||
        enDate?.toString() === "Invalid Date")
    ) {
      valid = false;
      error.endErr = true;
      error.endMsg = "Please enter valid date";
    } else if (stDate > enDate) {
      valid = false;
      error.endErr = true;
      error.endMsg = "Please enter valid date";
    }

    setErrObj(error);

    if (valid) {
      handleCloseCreation();
      let newMilestones = { ...proposalDetails.milestones };
      if (
        _.isObject(selectedMilestone?.data) &&
        !_.isEmpty(selectedMilestone?.data)
      ) {
        selectedMilestone.data = milestoneDetails;
        newMilestones[selectedMilestone.id] = selectedMilestone.data;
        setSelectedMilestone({});
      } else {
        const id = uuidv4();
        newMilestones[id] = { ...milestoneDetails };
      }
      dispatch(
        setProposalDetails({
          ...proposalDetails,
          milestones: { ...newMilestones },
        })
      );
    }
    // setSelectedMilestone({});
  };

  const handleEdit = () => {
    setAnchorEl(null);
    setVisible(true);
    setMilestoneDetails(selectedMilestone.data);
  };

  const handleDelete = () => {
    setAnchorEl(null);
    setDeleteModal(true);
    setMilestoneDetails(selectedMilestone.data);
  };
  const getNewMilestones = () => {
    // Remove the milestone from the milestones list
    const newMilestones = { ...proposalDetails.milestones };
    for (let key in proposalDetails.milestones) {
      if (key == selectedMilestone.id) {
        delete newMilestones[key];
      }
    }
    return newMilestones;
  };

  const getNewBudgets = () => {
    // Check and remove the budget from the budgets list
    const newBudgets = { ...proposalDetails.budgets };
    for (let budgetKey in newBudgets) {
      if (newBudgets[budgetKey].milestone_id == selectedMilestone.id) {
        delete newBudgets[budgetKey];
      }
    }
    return newBudgets;
  };
  const getNewPaymentGroups = () => {
    // Check and remove the milestone from the payment group
    const newPaymentGroups = { ...proposalDetails.payment_group };
    for (let groupKey in newPaymentGroups) {
      if (newPaymentGroups[groupKey].group[selectedMilestone.id]) {
        delete newPaymentGroups[groupKey].group[selectedMilestone.id];
      }
      if (!Object.keys(newPaymentGroups[groupKey].group).length) {
        delete newPaymentGroups[groupKey];
      }
    }
    return newPaymentGroups;
  };

  const handleDeleteMilestone = () => {
    // const newMilestones = { ...proposalDetails.milestones };
    // for (let key in proposalDetails.milestones) {
    //   if (key !== selectedMilestone.id) {
    //     newMilestones[key] = proposalDetails.milestones[key];
    //   }
    // }
    // const newPaymentGroup = { ...proposalDetails.payment_group };
    // const newBudgets = { ...proposalDetails.budgets };
    // Remove the milestone from the milestones list
    // for (let key in proposalDetails.milestones) {
    //   if (key == selectedMilestone.id) {
    //     delete newMilestones[key];
    //   }
    // }
    // Check and remove the milestone from the payment group
    // for (let groupKey in newPaymentGroup) {
    //   if (newPaymentGroup[groupKey].group[selectedMilestone.id]) {
    //     delete newPaymentGroup[groupKey].group[selectedMilestone.id];
    //   }
    //   if (!Object.keys(newPaymentGroup[groupKey].group).length) {
    //     delete newPaymentGroup[groupKey];
    //   }
    // }

    // Check and remove the milestone from the budgets
    // for (let budgetKey in newBudgets) {
    //   if (newBudgets[budgetKey].milestone_id == selectedMilestone.id) {
    //     delete newBudgets[budgetKey];
    //   }
    // }
    let newMilestones = getNewMilestones();
    let newBudgets = getNewBudgets();
    dispatch(
      setProposalDetails({
        ...proposalDetails,
        milestones: { ...newMilestones },
        payment_group: { ...getNewPaymentGroups() },
        budgets: { ...newBudgets },
      })
    );
    dispatch(
      setErrors({
        ...errors,
        isMilestonesValid: !!Object.keys(newMilestones).length,
        isBudgetsValid: !!Object.keys(newBudgets).length,
      })
    );
    // setMilestones(newMilestones);
    setSelectedMilestone({});
    setMilestoneDetails({});
    setDeleteModal(false);
  };
  // const validateMilestones = () => {
  //   if (Object.keys(proposalDetails.milestones).length < 1) {
  //     toast.error("Please add at least one milestone");
  //   }
  //   return Object.keys(proposalDetails.milestones).length;
  // }
  // const handleSubmit = () => {
  //   let isValid = validateMilestones();
  //   if(!isValid) return;
  //   // const budgets = updatedBudgets;
  //   //
  //   // const milestoneIds = Object.keys(milestones);
  //   // const budgetIds = Object.keys(budgets);
  //   // const paymentGroup = updatePaymentGroup();
  //   //
  //   // dispatch(
  //   //   setProposalDetails({
  //   //     ...proposalDetails,
  //   //     budgets,
  //   //     budgetIds,
  //   //     milestones,
  //   //     milestoneIds,
  //   //     payment_group: paymentGroup,
  //   //     start_date: startDate,
  //   //     end_date: endDate,
  //   //   })
  //   // );
  //   // handleDisableTab("budgetTab");
  //
  //   handleTabValue(2);
  // };
  //
  // const handlePreviousStep = () => {
  //   // const budgets = updatedBudgets;
  //   //
  //   // const milestoneIds = Object.keys(milestones);
  //   // const budgetIds = Object.keys(budgets);
  //   // const paymentGroup = updatePaymentGroup();
  //   //
  //   // dispatch(
  //   //   setProposalDetails({
  //   //     ...proposalDetails,
  //   //     budgets,
  //   //     budgetIds,
  //   //     milestones,
  //   //     milestoneIds,
  //   //     payment_group: paymentGroup,
  //   //     start_date: startDate,
  //   //     end_date: endDate,
  //   //   })
  //   // );
  //   handleTabValue(0);
  // };

  // const handleConfirm = () => {
  //   setIsOpen(false);
  //   dispatch(resetProposalDetails());
  //   navigate("/dashboard");
  // };
  // const convertBase64ToImageFile = (base64String, filename) => {
  //   const arr = base64String?.split(",");
  //   const mimeType = arr[0].match(/:(.*?);/)[1];
  //   const bstr = atob(arr[1]);
  //   let n = bstr.length;
  //   const uint8Array = new Uint8Array(n);
  //
  //   while (n--) {
  //     uint8Array[n] = bstr.charCodeAt(n);
  //   }
  //   const file = new File([uint8Array], filename, { type: mimeType });
  //
  //   return file;
  // };

  // const convertProjectToFiles = () => {
  //   const projectFiles = proposalDetails?.project?.map(
  //     (base64String, index) => {
  //       const filename = `project_image_${index + 1}.jpg`;
  //       return convertBase64ToImageFile(base64String, filename);
  //     }
  //   );
  //   return projectFiles;
  // };
  // const proposalId = isUpdateDraftProposal
  //   ? villa?.proposal_id
  //   : proposalDetails.saveDraftProposalId
  //   ? proposalDetails.saveDraftProposalId
  //   : null;
  // const query = isUpdateDraftProposal ? `?proposal_id=${proposalId}` : "";
  // const apiUrl = isUpdateDraftProposal
  //   ? `${Setting.endpoints.createDraftProposal}?proposal_id=${villa.proposal_id}`
  //   : proposalDetails.saveDraftProposalId
  //     ? `${Setting.endpoints.createDraftProposal}?proposal_id=${proposalDetails.saveDraftProposalId}`
  //     : Setting.endpoints.createDraftProposal;
  // const handleSaveDraftConfirm = () => {
  //   let isValid = validateMilestones();
  //   if (!isValid) return;
  //   setIsConfirmSaveDraft(true);
  // };
  // const handleSaveDraft = async () => {
  //   const projectFiles = convertProjectToFiles();
  //   const payload = getSaveDraftPayload({
  //     proposalDetails,
  //     projectFiles,
  //     milestones: proposalDetails.milestones
  //   });
  //   try {
  //     const response = await getAPIProgressData(
  //       `${Setting.endpoints.createDraftProposal}${query}`,
  //       "POST",
  //       payload,
  //       true
  //     );
  //
  //     if (response.success) {
  //       dispatch(setProposalDetails({
  //         ...proposalDetails,
  //         saveDraftProposalId: proposalId,
  //       }));
  //       toast.success("Draft created Successfully");
  //       navigate("/dashboard");
  //     } else {
  //       toast.error(response.message);
  //     }
  //   } catch (error) {
  //     console.log("save draft api error", error);
  //     toast.error(error.toString());
  //   }
  // };

  const handleTabChange = (event, newValue) => {
    setMilestoneDetails({
      ...milestoneDetails,
      tags: newValue
    });
  };

  const currentTab = milestoneDetails?.tags;

  function renderMilestoneCreateForm(mode) {
    return (
      <Modal open={isCreationOpen || visible} onClose={handleCloseCreation}>
        <Fade in={isCreationOpen || visible}>
          <Box sx={style}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              {mode === "modal" ? (
                <div className="addMilestoneHeader">Update Milestone</div>
              ) : (
                <div className="addMilestoneHeader">Create Milestone</div>
              )}
              <Close
                style={{ cursor: "pointer" }}
                onClick={() => handleCloseCreation()}
              />
            </div>
            <Grid container>
            <Grid item xs={12} mt={2}>
              <div style={{display:"flex", flexDirection:"column", gap:"10px"}}>
              <span style={{fontSize:"14px"}}>Milestone Type</span>
              <Select
                  options={milestoneTypes?.map((type) => ({
                    value: type.value,
                    label: type.label,
                  }))}
                  value={milestoneTypes?.find((type) => type.value === currentTab)}
                  onChange={(selectedOption) => handleTabChange(null, selectedOption.value)}
                />
              </div>
             </Grid>

              <Grid item xs={12} id="name" mt={2}>
                <CInput
                  label={<span>Milestone Name</span>}
                  placeholder="Enter Milestone Name..."
                  value={
                    mode === "modal" && visibleEditModal
                      ? milestoneDetails.milestone_name
                      : mode === "form" && visibleEditModal
                      ? ""
                      : milestoneDetails.milestone_name
                  }
                  onChange={(e) => {
                    setMilestoneDetails({
                      ...milestoneDetails,
                      milestone_name: e.target.value,
                    });
                    setErrObj({
                      ...errObj,
                      nameErr: false,
                      nameMsg: "",
                    });
                  }}
                  inputProps={{ maxLength: 50 }}
                  error={
                    mode === "modal" && visibleEditModal
                      ? errObj.nameErr
                      : mode === "form" && visibleEditModal
                      ? ""
                      : errObj.nameErr
                  }
                  helpertext={
                    mode === "modal" && visibleEditModal
                      ? errObj.nameMsg
                      : mode === "form" && visibleEditModal
                      ? ""
                      : errObj.nameMsg
                  }
                />
              </Grid>
              <Grid item xs={12} id="description" mt={2}>
                <CInput
                  multiline={true}
                  rows={3}
                  label={<span>Description:</span>}
                  placeholder="Write description here..."
                  value={
                    mode === "modal" && visibleEditModal
                      ? milestoneDetails.description
                      : mode === "form" && visibleEditModal
                      ? ""
                      : milestoneDetails.description
                  }
                  onChange={(e) => {
                    setMilestoneDetails({
                      ...milestoneDetails,
                      description: e.target.value,
                    });
                    setErrObj({
                      ...errObj,
                      descriptionErr: false,
                      descriptionMsg: "",
                    });
                  }}
                  error={
                    mode === "modal" && visibleEditModal
                      ? errObj.descriptionErr
                      : mode === "form" && visibleEditModal
                      ? ""
                      : errObj.descriptionErr
                  }
                  helpertext={
                    mode === "modal" && visibleEditModal
                      ? errObj.descriptionMsg
                      : mode === "form" && visibleEditModal
                      ? ""
                      : errObj.descriptionMsg
                  }
                />
              </Grid>

              <Grid
                item
                container
                columnGap={1}
                wrap={md ? "wrap" : "nowrap"}
                mt={2}
              >
                <Grid item xs={12} md={6} mb={2}>
                  <FormControl
                    variant="standard"
                    fullWidth
                    error={
                      mode === "modal" && visibleEditModal
                        ? errObj.startErr
                        : mode === "form" && visibleEditModal
                        ? ""
                        : errObj.startErr
                    }
                    style={{ position: "relative" }}
                  >
                    <span className="fieldTitle" htmlFor="start-date">
                      Start Date:
                    </span>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        disablePast
                        value={
                          mode === "modal" && visibleEditModal
                            ? new Date(milestoneDetails.start_date)
                            : mode === "form" && visibleEditModal
                            ? null
                            : milestoneDetails.start_date
                            ? new Date(milestoneDetails?.start_date)
                            : null
                        }
                        onChange={(e, v) => {
                          setMilestoneDetails({
                            ...milestoneDetails,
                            start_date: transformDateFormat(e),
                            end_date: null,
                          });
                          setErrObj({
                            ...errObj,
                            startErr: false,
                            startMsg: "",
                          });
                        }}
                        sx={{
                          width: "100%",
                          marginTop: "6px",
                        }}
                        components={{
                          OpenPickerIcon: () => (
                            <img
                              src={Images.calendarIcon}
                              alt="calender-icon"
                            ></img>
                          ),
                        }}
                        format="MMMM dd, yyyy"
                        slotProps={{
                          textField: {
                            helperText:
                              mode === "modal" && visibleEditModal
                                ? errObj.startMsg
                                : mode === "form" && visibleEditModal
                                ? ""
                                : errObj.startMsg,
                            error:
                              mode === "modal" && visibleEditModal
                                ? errObj.startErr
                                : mode === "form" && visibleEditModal
                                ? ""
                                : errObj.startErr,
                            id: "start-date",
                          },
                        }}
                      />
                    </LocalizationProvider>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6} mb={2}>
                  <FormControl
                    variant="standard"
                    fullWidth
                    error={
                      mode === "modal" && visibleEditModal
                        ? errObj.endErr
                        : mode === "form" && visibleEditModal
                        ? ""
                        : errObj.endErr
                    }
                    style={{ position: "relative" }}
                  >
                    <span className="fieldTitle" htmlFor="end-date">
                      End Date:
                    </span>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        minDate={new Date(milestoneDetails?.start_date)}
                        value={
                          mode === "modal" && visibleEditModal
                            ? new Date(milestoneDetails.end_date)
                            : mode === "form" && visibleEditModal
                            ? null
                            : milestoneDetails?.end_date
                            ? new Date(milestoneDetails?.end_date)
                            : null
                        }
                        onChange={(e) => {
                          setMilestoneDetails({
                            ...milestoneDetails,
                            end_date: transformDateFormat(e),
                          });
                          setErrObj({
                            ...errObj,
                            endErr: false,
                            endMsg: "",
                          });
                        }}
                        sx={{
                          width: "100%",
                          marginTop: "6px",
                        }}
                        components={{
                          OpenPickerIcon: () => (
                            <img
                              src={Images.calendarIcon}
                              alt="calender-icon"
                            ></img>
                          ),
                        }}
                        slotProps={{
                          textField: {
                            helperText:
                              mode === "modal" && visibleEditModal
                                ? errObj.endMsg
                                : mode === "form" && visibleEditModal
                                ? ""
                                : errObj.endMsg,
                            error:
                              mode === "modal" && visibleEditModal
                                ? errObj.endErr
                                : mode === "form" && visibleEditModal
                                ? ""
                                : errObj.endErr,
                            id: "end-date",
                          },
                        }}
                        format="MMMM dd, yyyy"
                      />
                    </LocalizationProvider>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            <Divider />
            <Stack padding="0px 8px 8px 0px" maxHeight="150px" overflow="auto">
              {Object.keys(proposalDetails.milestones)
                .filter((key) => key !== selectedMilestone.id)
                .map((key) => {
                  const milestone = proposalDetails.milestones[key];
                  return (
                    <Box key={key}>
                      {milestone.start_date ? (
                        <Stack
                          direction="row"
                          justifyContent="space-between"
                          alignItems="center"
                          padding="12px 0px"
                          fontFamily="Poppins-Regular !important"
                          sx={{ userSelect: "none" }}
                        >
                          <Stack direction="row" gap="4px">
                            <Stack>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                              >
                                <path
                                  d="M5 22V14M5 14V4M5 14L7.47067 13.5059C9.1212 13.1758 10.8321 13.3328 12.3949 13.958C14.0885 14.6354 15.9524 14.7619 17.722 14.3195L17.9364 14.2659C18.5615 14.1096 19 13.548 19 12.9037V5.53669C19 4.75613 18.2665 4.18339 17.5092 4.3727C15.878 4.78051 14.1597 4.66389 12.5986 4.03943L12.3949 3.95797C10.8321 3.33284 9.1212 3.17576 7.47067 3.50587L5 4M5 4V2"
                                  stroke="#274BF1"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                />
                              </svg>
                            </Stack>
                            <Typography fontSize="14px">
                              {milestone.milestone_name}
                            </Typography>
                          </Stack>
                          <Typography width="50%" fontSize="14px">
                            {transformDateFormat(milestone.start_date)}-{" "}
                            {transformDateFormat(milestone.end_date)}
                          </Typography>
                        </Stack>
                      ) : (
                        <div></div>
                      )}

                      <Divider />
                    </Box>
                  );
                })}
            </Stack>
            <Grid
              item
              container
              justifyContent="center"
              gap={sm ? 1 : 2}
              wrap="nowrap"
              marginTop="10px"
            >
              <Grid item xs={6}>
                <div className="cancel" onClick={handleCloseCreation}>
                  Cancel
                </div>
              </Grid>
              <Grid item xs={6}>
                {mode === "modal" ? (
                  <Button
                    fullWidth
                    variant="contained"
                    onClick={() => validate(false)}
                  >
                    Update Milestone
                  </Button>
                ) : (
                  <Button
                    fullWidth
                    variant="contained"
                    onClick={() => validate(false)}
                  >
                    Create Milestone
                  </Button>
                )}
              </Grid>
            </Grid>
          </Box>
        </Fade>
      </Modal>
    );
  }
  return (
    <Stack width="100%" height="90%" gap="16px">
      {/*<div className="alert">*/}
      {/*  <span className="label">Total Milestones Amount</span>*/}
      {/*  <span className="cur">AED {totalBudgetAmount}</span>*/}
      {/*</div>*/}

      {/*<Divider width="100%" />*/}

      {isCreationOpen && renderMilestoneCreateForm("form")}
      {visible && renderMilestoneCreateForm("modal")}

      <Stack width="100%" height="100%" gap="16px" overflow="auto">
        {/*<div className="secondaryTitle">Milestones</div>*/}
        {/*<Divider width="100%" />*/}
        <Grid container>
          <Stack width="100%" gap="8px">
            {Object.keys(proposalDetails.milestones).map((key) => {
              const milestone = proposalDetails.milestones[key];
              return (
                <SingleMilestoneAccordion
                  milestone={milestone}
                  id={key}
                  handleRowClick={handleRowClick}
                />
              );
            })}
          </Stack>
        </Grid>
      </Stack>
      <Grid item container alignItems={"center"} padding="0px 24px">
        <div
          className="btnSubmit"
          onClick={() => {
            setIsCreationOpen(true);
          }}
        >
          <AddCircleOutlineOutlinedIcon style={{ marginRight: 4 }} />
          Add Milestone
        </div>
      </Grid>

      <Divider width="100%" />

      {/*<Stack direction="row" justifyContent="space-between">*/}
      {/*  <Stack>*/}
      {/*    <Button*/}
      {/*      variant="outlined"*/}
      {/*      size="small"*/}
      {/*      sx={{ boxShadow: "none", padding: "12px 24px" }}*/}
      {/*      onClick={handlePreviousStep}*/}
      {/*    >*/}
      {/*      Previous Step*/}
      {/*    </Button>*/}
      {/*  </Stack>*/}
      {/*  <Stack direction="row" gap={2}>*/}
      {/*    <Button*/}
      {/*      variant="outlined"*/}
      {/*      onClick={() => setIsOpen(true)}*/}
      {/*      style={{ padding: "12px 24px" }}*/}
      {/*    >*/}
      {/*      Cancel*/}
      {/*    </Button>*/}
      {/*    <Button*/}
      {/*      variant="contained"*/}
      {/*      onClick={handleSubmit}*/}
      {/*      style={{ padding: "12px 24px" }}*/}
      {/*    >*/}
      {/*      Save And Continue*/}
      {/*    </Button>*/}
      {/*    <Button variant="contained" size="large" onClick={handleSaveDraftConfirm}>*/}
      {/*      Save Draft*/}
      {/*    </Button>*/}
      {/*  </Stack>*/}
      {/*</Stack>*/}
      {/* milestone 3 dots menu: edit, delete*/}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            ml: 8,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
          },
        }}
        transformOrigin={{
          horizontal: "right",
          vertical: "top",
        }}
        anchorOrigin={{
          horizontal: "right",
          vertical: "bottom",
        }}
      >
        <MenuItem
          style={{
            fontFamily: "Poppins-Regular",
            padding: "12px 36px 12px 12px",
          }}
          onClick={handleEdit}
        >
          Edit
        </MenuItem>
        <Divider style={{ margin: 0 }} />
        <MenuItem
          style={{
            fontFamily: "Poppins-Regular",
            padding: "12px 36px 12px 12px",
          }}
          onClick={handleDelete}
        >
          Delete
        </MenuItem>
      </Menu>

      <ConfirmModel
        visible={deleteModal}
        handleClose={() => setDeleteModal(false)}
        confirmation={handleDeleteMilestone}
        message={`Are you sure you want to delete ${selectedMilestone?.data?.milestone_name} milestone?`}
      />
      {/*<ConfirmModel*/}
      {/*  visible={isOpen}*/}
      {/*  handleClose={() => setIsOpen(false)}*/}
      {/*  confirmation={handleConfirm}*/}
      {/*  message="Are you sure to discard the changes"*/}
      {/*/>*/}
      {/*<ConfirmModel*/}
      {/*  visible={isConfirmSaveDraft}*/}
      {/*  handleClose={() => setIsConfirmSaveDraft(false)}*/}
      {/*  confirmation={handleSaveDraft}*/}
      {/*  message="Are you sure you want to save and quit?"*/}
      {/*/>*/}
    </Stack>
  );
};
export default Milestones;
