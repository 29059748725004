const actions = {
  SET_IS_CONFIRM_MODAL_SAVE_DRAFT_OPEN: "auth/SET_IS_CONFIRM_MODAL_SAVE_DRAFT_OPEN",
  SET_IS_CANCEL_MODAL_OPEN: "auth/SET_IS_CANCEL_MODAL_OPEN",

  setIsConfirmModalSaveDraftOpen: (value) => (dispatch) =>
    dispatch({
      type: actions.SET_IS_CONFIRM_MODAL_SAVE_DRAFT_OPEN,
      isConfirmModalSaveDraftOpen: value,
    }),
  setIsCancelModalOpen: (value) => (dispatch) =>
    dispatch({
      type: actions.SET_IS_CANCEL_MODAL_OPEN,
      isCancelModalOpen: value,
    })
};

export default actions;
