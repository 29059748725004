const Images = {
  header_logo: "/assets/images/logo.png",
  worksBg: "/assets/images/worksBg.png",
  canvas: "/assets/images/canvas.svg",
  Pattern: "/assets/images/Pattern.svg",
  google: "/assets/images/google.svg",
  // fb: "/assets/images/fb_white.svg",
  apple: "/assets/images/apple.svg",
  flogo: "/assets/images/flogo.png",
  success: "/assets/images/success.png",
  chatico: "/assets/images/chatico.svg",
  BellSimple: "/assets/images/BellSimple.svg",
  verified: "/assets/images/verified.svg",
  share: "/assets/images/share.svg",
  copy: "/assets/images/copy.svg",
  Location: "/assets/images/Location.svg",
  LocationBlue: "/assets/images/LocationBlue.svg",
  bedroom: "/assets/images/badroom.svg",
  bathroom: "/assets/images/bathroom.svg",
  size: "/assets/images/size.svg",
  profile_logo: "/assets/images/profile_logo.svg",
  add: "/assets/images/add.svg",
  file: "/assets/images/file.svg",
  arrow: "/assets/images/arrow.svg",
  close: "/assets/images/closep.svg",
  editPaymentGroup: "/assets/images/edit-02.svg",

  Heart: "/assets/images/Heart.svg",
  reply: "/assets/images/reply.svg",
  building: "/assets/images/building.png",
  profile: "/assets/images/profile.png",
  upload: "/assets/images/upload.svg",
  upload_icon: "/assets/images/uploadicon.svg",
  calendarIcon: "/assets/images/calendarIcon.svg",
  Facebook: "/assets/images/Facebook.svg",
  Instagram: "/assets/images/Instagram.svg",
  Yt: "/assets/images/Yt.svg",
  Linkedin: "/assets/images/Linkedin.svg",
  doller: "/assets/images/dollar-circle.png",
  globe: "/assets/images/globe.png",
  laptop: "/assets/images/laptop.png",
  Phone: "/assets/images/Phone.png",
  Linkedin1: "/assets/images/linkedin.png",
  Linkedin2: "/assets/images/linkedin 1.png",
  Fb1: "/assets/images/facebook.png",
  logo_white: "/assets/images/logo_white.svg",
  logo_black: "/assets/images/logo_black.svg",
  fb_white: "/assets/images/fb_white.svg",
  fb: "/assets/images/fb.png",
  twitter: "/assets/images/twitter.svg",
  insta_white: "/assets/images/insta_white.svg",
  ios_store: "/assets/images/ios_store.png",
  googleplay: "/assets/images/googlePlay.png",

  icon1: "/assets/images/icon1.svg",
  icon2: "/assets/images/icon2.svg",
  icon3: "/assets/images/icon3.svg",
  icon4: "/assets/images/icon4.svg",
  icon5: "/assets/images/icon5.svg",
  icon6: "/assets/images/icon6.svg",
  icon7: "/assets/images/icon7.svg",
  icon8: "/assets/images/icon8.svg",
  icon9: "/assets/images/icon9.svg",
  icon10: "/assets/images/icon10.svg",
  icon11: "/assets/images/icon11.svg",
  icon12: "/assets/images/icon12.svg",
  icon13: "/assets/images/icon13.svg",
  icon14: "/assets/images/icon14.svg",
  icon15: "/assets/images/icon15.svg",
  icon16: "/assets/images/icon16.svg",
  icon17: "/assets/images/icon17.svg",
  quote: "/assets/images/quote.svg",

  eye: "/assets/images/eye.svg",
  dollar: "/assets/images/dollar.svg",
  verify_green: "/assets/images/verify_green.png",
  kitchen_1: "/assets/images/kitchen-galley.svg",
  corridor: "/assets/images/Corridor-SHaped.svg",
  l_shaped: "/assets/images/L-SHaped.svg",
  Peninsula: "/assets/images/Peninsula.svg",
  U_shape: "/assets/images/U-shape.svg",
  b2_pieces: "/assets/images/Bathroom-2-pieces.svg",
  b3_pieces: "/assets/images/Bathroom-3-pieces.svg",
  b4_pieces: "/assets/images/Bathroom-4-pieces.svg",
  playstore: "/assets/images/play-store.svg",
  pdf: "/assets/images/pdf.png",
  edit: "/assets/images/Edit.svg",
  FileVerified: "/assets/images/file-verified.svg",
  FileUnknown: "/assets/images/file-unknown.svg",
  FilePaste: "/assets/images/file-paste.svg",
  FileEdit: "/assets/images/file-edit.svg",
  FileAdd: "/assets/images/file-add.svg",
  FileBlock: "/assets/images/file-block.svg",
  editIcon: "/assets/images/content.png",
  pictureUpload: "/assets/images/content.svg",
  fileUpload: "/assets/images/fileLink.svg",
  send: "/assets/images/Send.svg",
  mute: "/assets/images/bell.svg",
  search: "/assets/images/search.svg",
  people: "/assets/images/people.png",
  doc: "/assets/images/Document.png",
  dots: "/assets/images/Dots.svg",
};

export default Images;
