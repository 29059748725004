import { Stack, Typography, Divider } from "@mui/material";
import React from "react";
import useStyles from "./styles";

function ProjectDetails({ proposalDetails }) {
  const classes = useStyles();

  return (
    <>
      <Stack width="100%" gap="16px">
        <Typography
          className={classes.projectInformation}
          sx={{ backgroundColor: "#F3F4F9" }}
        >
          Project Details
        </Typography>
        <Stack gap="8px" width="100%">
          <Stack
            direction="row"
            gap="8px"
            padding="0 12px"
            width="100%"
            justifyContent="space-between"
            flexWrap="wrap"
          >
            <Stack>
              <Typography className={classes.informationCard}>
                Project Name
              </Typography>
              <Typography className={classes.value}>{proposalDetails?.name}</Typography>
            </Stack>
            <Stack>
              <Typography className={classes.informationCard}>
                Project Type
              </Typography>
              <Typography className={classes.value}>{proposalDetails?.project_type}</Typography>
            </Stack>

            <Stack>
              <Typography className={classes.informationCard}>Email</Typography>
              <Typography className={classes.value}>{proposalDetails?.email || "NA"}</Typography>
            </Stack>
            {proposalDetails?.start_date ? (
              <Stack>
                <Typography className={classes.informationCard}>
                  Project Dates
                </Typography>
                <Typography className={classes.value}>
                  {proposalDetails?.start_date}-{proposalDetails?.end_date}
                </Typography>
              </Stack>
            ) : (
              <Stack></Stack>
            )}
          </Stack>
          <Divider />
          {proposalDetails?.scope_of_work && (
            <Stack padding="0 12px">
              <Stack>
                <Typography className={classes.informationCard}>
                  Scope of work
                </Typography>
                <Typography className={classes.value}>
                  {proposalDetails?.scope_of_work}
                </Typography>
              </Stack>
            </Stack>
          )}
          {proposalDetails?.scope_of_work && <Divider />}
          {proposalDetails?.description && (
            <Stack padding="0 12px">
              <Stack>
                <Typography className={classes.informationCard}>
                  Project Description
                </Typography>
                <Typography className={classes.value}>{proposalDetails?.description}</Typography>
              </Stack>
            </Stack>
          )}
        </Stack>
        {proposalDetails?.description && <Divider />}
      </Stack>
    </>
  );
}

export default ProjectDetails;