import React, { useEffect, useState } from "react";
import MilestoneList from "./MilestoneList";
import MilestoneUploadPdf from "./MilestoneUploadPdf";
import { isEmpty } from "lodash";
import { getApiData } from "../../../../../utils/APIHelper";
import { Setting } from "../../../../../utils/Setting";
import { CircularProgress } from "@mui/material";
import MilestoneCard from "./MilestoneCard";
import moment from "moment";

function MilestoneUpdates({ data, setDeleteFile, milestone }) {
  const [extraDetails, setExtraDetails] = useState([]);
  const [loader, setLoader] = useState(false);
  const [showDetails, setShowDetails] = useState({});
  const [showLoader, setShowLoader] = useState(false);

  async function getExtraMilestoneUpdates(id, date) {
    setShowLoader(true);
    try {
      const response = await getApiData(
        `${Setting.endpoints.milestoneUpdates}/${id}?type=milestone&tags=Milestone_Update&date=${date}`,
        "GET",
        {}
      );
      if (response.success) {
        setExtraDetails(response?.data);
        setShowDetails({ status: true, date: date });
        setDeleteFile({});
        setShowLoader(false);
        console.log("response", response);
      }
    } catch (error) {
      console.log("err===>", error);
      setShowLoader(false);
    }
  }

  return (
    <>
      <br />
      <h2>Milestone Updates</h2>
      <br />
      <MilestoneUploadPdf
        milestoneId={milestone?.id}
        setDeleteFile={setDeleteFile}
        setLoader={setLoader}
        setShowDetails={setShowDetails}
      />
      <br />
      {loader ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress size={24} />
        </div>
      ) : (
        <>
          {!isEmpty(data) && (
            <>
              <MilestoneCard
                arr={data}
                getExtraMilestoneUpdates={getExtraMilestoneUpdates}
              />
            </>
          )}

          {showDetails &&
            showDetails?.status &&
            (showLoader ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "10px",
                }}
              >
                <CircularProgress size={24} />
              </div>
            ) : !isEmpty(extraDetails) ? (
                <>
                <h4 style={{marginTop:".5vh"}}>Showing Updates for {moment(showDetails?.date).format("MMMM DD, YYYY")}</h4>
                <MilestoneList
                  arr={extraDetails}
                  setDeleteFile={setDeleteFile}
                  setLoader={setLoader}
                  setShowDetails={setShowDetails}
                />
              </>
            ) : (
              <center>No updates found on {showDetails?.date}</center>
            ))}
        </>
      )}
    </>
  );
}

export default MilestoneUpdates;
