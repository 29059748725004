import React from 'react';
import { Card, Grid, Typography, Box } from '@mui/material';
import moment from 'moment';

const InspectionDetailsCard = ({ details }) => {
  const resultColor = details?.result === 'PASS' ? '#52c41a' : details?.result === 'FAIL' ? '#ff4d4f' : 'inherit';

  return (
    <>
    <Card sx={{ margin: 3, padding: 2, paddingBottom: 4 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <Typography
              sx={{
                fontFamily: 'Poppins-SemiBold',
                color: 'rgba(0, 0, 0, 0.87)',
                flexShrink: 0,
                mr: 1,
              }}
            >
              Project Name:
            </Typography>
            <Typography
              fontFamily={'Poppins-Regular'}
              variant="body1"
              sx={{ wordBreak: 'break-word' }}
            >
              {details?.projectName ?? "---"}
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={12} md={6}>
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <Typography
              sx={{
                fontFamily: 'Poppins-SemiBold',
                color: 'rgba(0, 0, 0, 0.87)',
                flexShrink: 0,
                mr: 1,
              }}
            >
              Group ID:
            </Typography>
            <Typography
              fontFamily={'Poppins-Regular'}
              variant="body1"
              sx={{ wordBreak: 'break-word' }}
            >
              {details?.groupId ?? "---"}
            </Typography>
          </Box>
        </Grid>
      </Grid>

      <Grid container spacing={2} mt={1}>
        <Grid item xs={12} md={6}>
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <Typography
              sx={{
                fontFamily: 'Poppins-SemiBold',
                color: 'rgba(0, 0, 0, 0.87)',
                flexShrink: 0,
                mr: 1,
              }}
            >
              Due Date:
            </Typography>
            <Typography
              variant="body1"
              sx={{ wordBreak: 'break-word' }}
            >
            {details?.dueDate 
                ? moment(details?.dueDate).format('MMM DD, YYYY') 
                : "---"}
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={12} md={6}>
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <Typography
              sx={{
                fontFamily: 'Poppins-SemiBold',
                color: 'rgba(0, 0, 0, 0.87)',
                flexShrink: 0,
                mr: 1,
              }}
            >
              Inspection Date:
            </Typography>
            <Typography
              variant="body1"
              sx={{ wordBreak: 'break-word' }}
            >
            {details?.inspectionDate 
                ? moment(details?.inspectionDate).format('MMM DD, YYYY') 
                : "---"}
            </Typography>
          </Box>
        </Grid>
      </Grid>

      <Grid container spacing={2} mt={1}>
        <Grid item xs={12} md={6}>
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <Typography
              sx={{
                fontFamily: 'Poppins-SemiBold',
                color: 'rgba(0, 0, 0, 0.87)',
                flexShrink: 0,
                mr: 1,
              }}
            >
              Milestone Name:
            </Typography>
            <Typography
              variant="body1"
              sx={{ wordBreak: 'break-word' }}
            >
              {details?.milestoneName ?? "---"}
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={12} md={6}>
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <Typography
              sx={{
                fontFamily: 'Poppins-SemiBold',
                color: 'rgba(0, 0, 0, 0.87)',
                flexShrink: 0,
                mr: 1,
              }}
            >
              Result:
            </Typography>
            <Typography
              variant="body1"
              sx={{
                wordBreak: 'break-word',
                color: resultColor,
              }}
            >
              {details?.result === 'PASS' ? 'Passed' : details?.result === 'FAIL' ? 'Failed' : details?.result ?? "---"}
            </Typography>
          </Box>
        </Grid>
      </Grid>

      <Grid container spacing={2} mt={1}>
        <Grid item xs={12} md={6}>
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <Typography
              sx={{
                fontFamily: 'Poppins-SemiBold',
                color: 'rgba(0, 0, 0, 0.87)',
                flexShrink: 0,
                mr: 1,
              }}
            >
              Reason:
            </Typography>
            <Typography
              variant="body1"
              sx={{ wordBreak: 'break-word' }}
            >
              {details?.reason ?? "---"}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Card>

    {details?.notes && details?.notes !== "" &&
      <Card sx={{ margin: 3, padding: 2, paddingBottom: 4 }}>
        <Grid container spacing={2} mt={1}>
          <Grid item xs={12} >
            <Box>
              <Typography
                sx={{
                  fontFamily: 'Poppins-SemiBold',
                  color: 'rgba(0, 0, 0, 0.87)',
                  flexShrink: 0,
                  mr: 1,
                  marginBottom:".7vh"
                }}
              >
                Notes:
              </Typography>
              <Typography
                variant="body1"
                sx={{ wordBreak: 'break-word' }}
              >
                {details?.notes}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Card>
    }
    </>
  );
};

export default InspectionDetailsCard;
