import { getApiData } from "./APIHelper";
import { Setting } from "./Setting";
import { store } from "../redux/store/configureStore";
import authActions from "../redux/reducers/auth/actions";
import SingleMilestoneAccordion from "../components/SingleMilestoneAccordian";
import moment from "moment";

export const updateUserData = async () => {
  const { setUserData } = authActions;
  try {
    const response = await getApiData(Setting.endpoints.me, "GET", {});
    if (response.success) {
      store.dispatch(setUserData(response?.data));
      return response?.data;
    }
  } catch (error) {
    console.log("error ===>>>", error);
  }
};

export function renderMilestones(milestones, handleRowClick) {
  const allMilestones = [];
  for (let key in milestones) {
    allMilestones.push(
      <SingleMilestoneAccordion
        milestone={milestones[key]}
        id={key}
        handleRowClick={handleRowClick}
      />
    );
  }
  return allMilestones;
}

export function removeEmptyKeys(obj) {
  if (Array.isArray(obj)) {
    return obj
      .map((item) => removeEmptyKeys(item))
      .filter(
        (item) =>
          item !== null &&
          (typeof item !== "object" ||
            Object.keys(item).length > 0 ||
            item instanceof File)
      );
  } else if (typeof obj === "object" && obj !== null) {
    let newObj = {};
    if (obj instanceof File) {
      newObj = obj;
    } else {
      Object.keys(obj).forEach((key) => {
        const value = removeEmptyKeys(obj[key]);
        if (
          value !== null &&
          (typeof value !== "object" || Object.keys(value).length > 0)
        ) {
          newObj[key] = value;
        }
      });
    }
    return newObj;
  }
  return obj;
}

export const transformDateFormat = (date) => {
  return moment(date)?.format("MMM DD, yyyy");
};

export const transformAmount = (amount) => {
  return `AED ${amount?.toFixed(2)}`;
};

export function formattedAmount(amount) {
  if (isNaN(amount)) {
      return '';
  }
  let number = parseFloat(amount).toFixed(2);
  let [integerPart, decimalPart] = number.split('.');

  let formattedAmount = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  formattedAmount += '.' + decimalPart;

  return `AED ${formattedAmount}`;
}



