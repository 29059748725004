import {
  Divider,
  Grid,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useState } from "react";
import useStyles from "./styles";
import Images from "../../config/images";
import theme from "../../config/theme";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ImageViewer from "../ImageViewer";

import { TabPanel } from "../TabPanel";
import CometChatComponent from "./CometChatComponent";
import { isArray } from "lodash";
import { isEmpty } from "lodash";
import { formattedAmount } from "../../utils/CommonFunction";

const errorObj = {
  scpErr: false,
  scpMsg: "",
};

export default function ProposalCard(props) {
  const { villa } = props;
  const classes = useStyles();

  const nData = villa?.submitted_by_reno
    ? villa?.reno_data || {}
    : villa?.user_data || {};

  const [expandProjectInfo, setExpandProjectInfo] = useState(true);
  const [expandAttachments, setExpandAttachments] = useState(true);
  const [isPressed, setIsPressed] = useState(false);
  const [url, setUrl] = useState("");
  const [imgurl, setImgUrl] = useState("");
  const sm = useMediaQuery(theme.breakpoints.down("sm"));

  const [tabValueforcard, setTabValueforcard] = useState(0);

  return (
    <Grid item container xs={12}>
      <Grid
        item
        container
        wrap={sm ? "wrap" : "nowrap"}
        alignItems={"center"}
        justifyContent={"space-around"}
        columnGap={2}
      >
        <Grid item lg={2} md={4} sm={4} xs={12}>
          <img
            src={nData?.profile_url}
            alt="chat"
            className={classes.imageStyle}
          />
        </Grid>
        <Grid item container>
          <Grid item lg={9} md={9} sm={9} xs={9}>
            <Typography className={classes.titleText}>
              {nData?.username}
            </Typography>
          </Grid>

          <Grid item lg={9} md={9} sm={6} xs={6} style={{ marginTop: 5 }}>
            <span
              variant="contained"
              style={{
                backgroundColor:
                  villa?.status === "ongoing" ? "#5CC385" : "#E9B55C",
                padding: 8,
                fontSize: "10px",
                letterSpacing: "1.5px",
                lineHeight: "16px",
                borderRadius: 4,
                color: "#FFFFFF",
              }}
            >
              {villa?.status
                ?.split("-")
                ?.map((item) => item.charAt(0).toUpperCase() + item.slice(1))
                .join(" ")}
            </span>
          </Grid>
        </Grid>
      </Grid>
      <Grid item container className={classes.contentContainer} id="scope">
        <Grid item xs={12} style={{ borderBottom: "1px solid #F2F3F4" }}>
          <Tabs
            value={tabValueforcard}
            onChange={(v, b) => {
              setTabValueforcard(b);
            }}
            variant="fullWidth"
          >
            <Tab label="Summary" />
            {/* <Tab label="Chat" /> */}
          </Tabs>
        </Grid>
      </Grid>
      <TabPanel value={tabValueforcard} index={0}>
        <>
          <Accordion
            style={{ marginTop: 10, boxShadow: "none" }}
            expanded={expandProjectInfo}
            onChange={() => setExpandProjectInfo(!expandProjectInfo)}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon style={{ color: "#98A2B3" }} />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.accTitleText}>
                Project Information
              </Typography>
            </AccordionSummary>
            <Divider style={{ margin: "0 16px" }} />
            <AccordionDetails>
              <Grid
                item
                container
                alignItems="center"
                justifyContent={"flex-end"}
                rowSpacing={2}
              >
                <Grid item lg={4} sm={4} md={4} xs={4}>
                  <Typography className={classes.acctext}>
                    Project Name:
                  </Typography>
                </Grid>
                <Grid item lg={8} sm={8} md={8} xs={8} textAlign={"end"}>
                  <Typography className={classes.accRightText}>
                    {villa?.name}
                  </Typography>
                </Grid>
                <Grid item lg={4} sm={4} md={4} xs={4}>
                  <Typography className={classes.acctext}>
                    Project Type:
                  </Typography>
                </Grid>
                <Grid item lg={8} sm={8} md={8} xs={8} textAlign={"end"}>
                  <Typography className={classes.accRightText}>
                    {villa?.project_type}
                  </Typography>
                </Grid>
                <Grid item lg={4} sm={4} md={4} xs={4}>
                  <Typography className={classes.acctext}>Space:</Typography>
                </Grid>
                <Grid item lg={8} sm={8} md={8} xs={8} textAlign={"end"}>
                  <Typography className={classes.accRightText}>
                    {villa?.space || "-"}
                  </Typography>
                </Grid>
                {isArray(villa?.form_json) &&
                  !isEmpty(villa?.form_json) &&
                  villa?.form_json?.map((item, index) => {
                    return (
                      <>
                        <Grid item lg={4} sm={4} md={4} xs={4}>
                          <Typography className={classes.acctext}>
                            Project Type:
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          lg={8}
                          sm={8}
                          md={8}
                          xs={8}
                          textAlign={"end"}
                        >
                          <Typography className={classes.accRightText}>
                            {item?.selectType?.title}
                          </Typography>
                        </Grid>
                        <Grid item lg={4} sm={4} md={4} xs={4}>
                          <Typography className={classes.acctext}>
                            Space:
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          lg={8}
                          sm={8}
                          md={8}
                          xs={8}
                          textAlign={"end"}
                        >
                          <Typography className={classes.accRightText}>
                            {item?.size} sq
                          </Typography>
                        </Grid>
                      </>
                    );
                  })}
                
                <Grid item lg={4} sm={4} md={4} xs={4}>
                  <Typography className={classes.acctext}>
                    Project Location:
                  </Typography>
                </Grid>
                <Grid
                  item
                  container
                  lg={8}
                  sm={8}
                  md={8}
                  xs={8}
                  textAlign={"end"}
                  justifyContent={"flex-end"}
                  wrap="nowrap"
                  gap="4px"
                >
                  {/* <img
                    alt="logo"
                    src={Images.Location}
                    style={{ color: "#274BF1 !important" }}
                  /> */}
                  <Typography className={classes.linkText}>
                    {villa?.location || "-"}
                  </Typography>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
          <Accordion
            style={{ marginTop: 10, width: "100%", boxShadow: "none" }}
            expanded={expandAttachments}
            onChange={() => setExpandAttachments(!expandAttachments)}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon style={{ color: "#98A2B3" }} />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.accTitleText}>
                Attachments
              </Typography>
            </AccordionSummary>
            <Divider style={{ margin: "0 16px" }} />
            <AccordionDetails>
              <Grid item container alignContent={"center"}>
                <Grid item lg={12}>
                  {villa?.project_image?.map((item) => {
                    return (
                      <a
                        href={url ? `${url}` : null}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          onClick={() => {
                            if (item?.type?.includes("image")) {
                              setIsPressed(true);
                              setImgUrl(item?.image);
                              setUrl("");
                            } else {
                              setUrl(item?.image);
                              setImgUrl("");
                            }
                          }}
                          alt="logo"
                          src={
                            item?.type?.includes("image")
                              ? item?.image
                              : Images.pdf
                          }
                          style={{
                            cursor: "pointer",
                            width: "88px",
                            height: "88px",
                            borderRadius: "7px",
                            margin: "15px 5px",
                          }}
                        />
                      </a>
                    );
                  })}
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </>
      </TabPanel>
      <TabPanel value={tabValueforcard} index={1}>
        <CometChatComponent GUID={villa?.project_slug} />
      </TabPanel>
      {/* <ImageViewer
        url={imgurl}
        visible={isPressed}
        isPdf={url}
        onClose={() => {
          setIsPressed(false);
        }}
      /> */}
    </Grid>
  );
}
