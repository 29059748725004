import {combineReducers} from 'redux';
import auth from './auth/reducer';
import common from './common/reducer';
import socket from './Socket/reducers'

const rootReducer = combineReducers({
  auth,
  common,
  socket
});

export default rootReducer;
