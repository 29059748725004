import { color } from "./theme";

export const CHANGE_REQUEST_STATUS = {
  DRAFT: "DRAFT",
  AWAITING_CONTRACTOR_CONFIRMATION: "AWAITING_CONTRACTOR_CONFIRMATION",
  AWAITING_HOME_OWNER_CONFIRMATION: "AWAITING_HOME_OWNER_CONFIRMATION",
  AWAITING_ADMIN_CONFIRMATION: "AWAITING_ADMIN_CONFIRMATION",
  REJECTED_BY_HOME_OWNER: "REJECTED_BY_HOME_OWNER",
  APPROVED_BY_HOME_OWNER: "APPROVED_BY_HOME_OWNER",
  COMPLETED: "COMPLETED",
  ONGOING: "ONGOING",
};

export const CHANGE_REQUEST_STATUS_DATA = {
  DRAFT: {
    label: "Draft",
    color: color.disable,
  },
  AWAITING_CONTRACTOR_CONFIRMATION: {
    label: "Awaiting contractor confirmation",
    color: color.primary,
  },
  AWAITING_HOME_OWNER_CONFIRMATION: {
    label: "Awating homeowner confirmation",
    color: color.lightOrange,
  },
  AWAITING_ADMIN_CONFIRMATION: {
    label: "Awaiting admin confirmation",
    color: color.primary,
  },
  REJECTED_BY_HOME_OWNER: {
    label: "Rejected by homeowner",
    color: color.secondary,
  },
  APPROVED_BY_HOME_OWNER: {
    label: "Approved by homeowner",
    color: color.green,
  },
};
export const MATERIAL_UNITS = [
  "tonns",
  "Kg",
  "g",
  "lbs",
  "liter",
  "ml",
  "sqm",
  "meter",
  "item",
];
